import React, { Component } from "react";
import { connect } from "react-redux";
import { addArticle, setPictureVisibility, selectPicture, setStateKeyValue } from "../actions/index";
import {Grid, AutoSizer} from 'react-virtualized';
import TimelineCell from "./TimelineCell";
import VisibilitySensor from "react-visibility-sensor";
import ReactTextTransition, { presets } from "react-text-transition";
import PhotoEditor from "./PhotoEditor";
import $ from 'jquery';
import HorizontalScroll from 'react-scroll-horizontal'

var moment = require('moment');
const FirebaseManager = require("../classes/FirebaseManager.js");

const mapStateToProps = state => {
  return {
    selectedPicture: state.selectedPicture,
    visibleAchievements: state.visibleAchievements,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setStateKeyValue: (key, value) => dispatch(setStateKeyValue(key, value)),
    selectPicture: picture => dispatch(selectPicture(picture))
  };
}

function getSeasonalString(monthString) {
  const monthInt = parseInt(monthString);
  if (monthInt >= 11 || monthInt <= 1) {
    return "Winter"
  }
  if (monthInt >= 9) {
    return "Fall"
  }
  if (monthInt >= 5) {
    return "Summer"
  }
  if (monthInt >= 2) {
    return "Spring"
  }
}

class ConnectedTimelineScrollView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      month: "",
      year: "",
      changeDirection: 'up',
      moments:[],
      rowCount: 3,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.scrollToColumn != null && prevState.scrollToColumn !== this.state.scrollToColumn) {
      if (this.myGrid != null) {
        this.myGrid.scrollToCell({rowIndex: this.state.scrollToColumn[0], columnIndex: this.state.scrollToColumn[1]});
      }
    }
    if (prevProps.achievements != this.props.achievements) {
      this.calculateAchievementTitles();
    }
  }

  componentDidMount() {
    this.calculateAchievementTitles();
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    var newState = {};
    var colors = {};
    var currentRowCount = prevState.rowCount
    if (nextProps.rowCount != null) {
      currentRowCount = nextProps.rowCount;
      newState.rowCount = nextProps.rowCount;
    }

    if (nextProps.achievements != null) {
      for (var key in nextProps.achievements) {
        if (nextProps.achievements.hasOwnProperty(key)) {
          const achievement = nextProps.achievements[key];
          colors[achievement.refID] = achievement.color;
        }
      }
    }

    newState.scrollToColumn = nextProps.scrollToColumn;


    if (nextProps.pictures != null) {
      var moments = [];
      var currentMoment = {};
      var currentPictures = [];
      var columnCount = -1;
      for (var pictureKey in nextProps.pictures) {
        if (nextProps.pictures.hasOwnProperty(pictureKey)) {
          const picture = nextProps.pictures[pictureKey];
          const dateString = getSeasonalString(moment(picture.getTimestamp()).format("M")) + " " +  moment(picture.getTimestamp()).format("YYYY");


          if (dateString != null && currentMoment.dateString == null) {
            currentMoment.dateString = dateString;
          } else if (dateString != null && currentMoment.dateString !== dateString) {
            currentMoment.pictures = currentPictures;
            moments.push(currentMoment);

            currentMoment = {dateString: dateString};
            currentPictures = [];
          }
          if (currentPictures.length % currentRowCount == 0) {
            columnCount += 1;
          }
          picture.index = [(currentPictures.length % currentRowCount), columnCount];
          currentPictures.push(picture);
        }
      }
      currentMoment.pictures = currentPictures;
      moments.push(currentMoment);

      newState.moments = moments;
      newState.achievementColors = colors
    }



    return newState;
  }

  pictureAndMomentForIndex(row, column) {

    var counter = 0;
    for (var i = 0; i < this.state.moments.length; i++) {
      const moment = this.state.moments[i];
      const columnsNeeded = Math.ceil(moment.pictures.length / this.state.rowCount);
      if (column >= counter && column < counter + columnsNeeded) {
        const index = row + (column - counter) * this.state.rowCount;

        if (moment.pictures.length <= index) {
          return null;
        }
        return [moment.pictures[index], moment];
      }
      counter += columnsNeeded;
    }

    return null
  }

  onSectionRendered({columnStartIndex, columnStopIndex, rowStartIndex, rowStopIndex}) {
    var pictures = [];
    var month = null;
    var year = null;
    var thisTimestamp = 0;
    const showingSideBar = this.props.hideSidebar !== true;
    if (showingSideBar) {
      columnStopIndex += 2;
    }

    const timeline = document.getElementById("timelineScrollViewDiv-" + this.props.currentProject.refID);

    const leftScrollAmount = timeline.scrollLeft;
    const width = timeline.clientWidth;
    const scrollWidth = timeline.scrollWidth;

    var threshhold = 10

    var percentScrolled = Math.round(leftScrollAmount / (scrollWidth - width) * threshhold);
    var selectedPictureForTime = null;

    if (percentScrolled === threshhold) {
      var i = rowStopIndex;
      while (selectedPictureForTime == null && i >= 0) {
        selectedPictureForTime = this.pictureAndMomentForIndex(i, columnStopIndex);
        i--;
      }
    } else if (percentScrolled === 0) {
      selectedPictureForTime = this.pictureAndMomentForIndex(rowStartIndex, columnStartIndex);
    }

    if (selectedPictureForTime == null) {
      for (var col = columnStartIndex; col <= columnStopIndex; col++) {
        for (var row = rowStartIndex; row <= rowStopIndex; row++) {
          const pictureAndMoment = this.pictureAndMomentForIndex(row, col);
          if (pictureAndMoment != null) {
            const picture = pictureAndMoment[0]
            if (month == null && picture.getTimestamp() !== 0) {
              thisTimestamp = picture.getTimestamp();
              month = moment(picture.getTimestamp()).format("MMM");
              year = moment(picture.getTimestamp()).format("YYYY");
            }
            pictures.push(picture);
          }
        }
      }
    } else {
      pictures.push(selectedPictureForTime[0])
    }



    var changeDirection = "up";
    if (this.lastTimestamp != null && this.lastTimestamp > thisTimestamp) {
      changeDirection = "down"
    }

    this.lastTimestamp = thisTimestamp;
    if (month != null) {
      this.setState({month:month, year:year, changeDirection: changeDirection})
    }
    this.props.setPictureVisibility({pictures:pictures});
  }



  cellRenderer({columnIndex, key, rowIndex, isVisible, style}) {
    const picture = this.pictureAndMomentForIndex(rowIndex, columnIndex);
    if (picture == null) {
      return (
        <div key={key} style={style}>

        </div>
      )
    }

    if (picture[0] == picture[1].pictures[0]) {
      return (
        <div key={key} style={style} onClick={()=>{
            this.props.selectPicture(picture[0])
            this.props.setStateKeyValue("allSelectedPictures", this.props.pictures)
            this.props.setStateKeyValue("achievements", this.props.achievements)
          }} >
          <TimelineCell picture={picture[0]} title={picture[1].dateString} achievementColors={this.state.achievementColors}/>
        </div>
      );
    }

    return (
      <div key={key} style={style} onClick={()=>{
          this.props.selectPicture(picture[0])
          this.props.setStateKeyValue("allSelectedPictures", this.props.pictures)
          this.props.setStateKeyValue("achievements", this.props.achievements)
        }} >
        <TimelineCell picture={picture[0]} achievementColors={this.state.achievementColors}/>
      </div>
    );
  }

  handleScroll(e) {
    this.calculateAchievementTitles();
  }

  calculateAchievementTitles() {
    const timeline = document.getElementById("timelineScrollViewDiv-" + this.props.currentProject.refID);
    if (this.props.achievements == null || this.props.wiki != null) {
      return
    }

    const scroll = timeline.scrollLeft;
    const rightForDiv = timeline.clientWidth;
    const totalWidth = timeline.scrollWidth;


    for (var i = 0; i < this.props.achievements.length; i++) {
      const achievement = this.props.achievements[i];
      const picture = this.findPictureClosestToDate(achievement.getTimestamp());
      if (picture == null) {
        continue;
      }
      const column = picture.index[1]
      const rawX = column * 350;


      const offSetFromLeft = rawX - scroll
      const achievementTitle = document.getElementById("title-" + achievement.refID);
      if (offSetFromLeft > 0 && offSetFromLeft < rightForDiv + 50) {
        achievementTitle.style.left = "" + offSetFromLeft + "px"
        achievementTitle.style.opacity = 1;
      } else if (offSetFromLeft <= 0 && achievement.refID === this.props.currentAchievement.refID) {
        achievementTitle.style.left = "0px"
        achievementTitle.style.opacity = 1;
      } else {
        achievementTitle.style.opacity = 0;
      }
    }
  }

  findPictureClosestToDate(timestamp) {
    var closestPicture = this.props.pictures[0]
    for (var i = 0; i < this.props.pictures.length; i++) {
      const picture = this.props.pictures[i];
      const distance = picture.getTimestamp() - timestamp;
      if (distance >= -10000000 && Math.abs(distance) < Math.abs(closestPicture.getTimestamp() - timestamp)) {
        closestPicture = picture;
      }
    }
    return closestPicture;
  }

  render() {

    var columnCount = 0;
    for (var i = 0; i < this.state.moments.length; i++) {
      const moment = this.state.moments[i];
      const columnsNeeded = Math.ceil(moment.pictures.length / this.state.rowCount);
      columnCount += columnsNeeded;
    }

    var achievements = [];
    if (this.props.achievements != null) {
      achievements = this.props.achievements;
    }

    var scrollToColumn = 0;

    if (this.props.scrollToColumn != null) {
      scrollToColumn = this.props.scrollToColumn;
    }
    var text = ""
    if (this.props.currentAchievement != null) {
      text = this.props.currentAchievement.title;
    }

    var currentMonthDiv = (
      <div>
        <ReactTextTransition direction={this.state.changeDirection} inline text={text} className="medium-text" style={{width:'unset'}} />
      </div>
    )


    if (this.props.hideTopDate === true && this.props.wiki == null) {
      currentMonthDiv = null;
      const achievementTitles = achievements.map(achievement => {


        return (
          <div id={"title-" + achievement.refID} key={"title-" + achievement.refID} className="medium-text fadable" style={{position:'absolute', top:0, left:"-500px", height:'24px', lineHeight:'24px', opacity:1, fontWeight:'bold'}}>
            {achievement.title}
          </div>
        )
      })

      return (
        <div style={{height:'100%', position:'relative'}}>
          <div style={{height:'24px', position:'relative'}}>
            {currentMonthDiv}
            {achievementTitles}
          </div>
          <div className="topToBottomGrid" style={{height:'calc(100% - 24px)', position:'relative',}}>
            <AutoSizer>
              {({ width, height }) => {
              return <Grid
                ref={(ref) => this.myGrid = ref}
                cellRenderer={this.cellRenderer.bind(this)}
                columnCount={columnCount}
                columnWidth={350}
                height={height}
                rowCount={this.state.rowCount}
                rowHeight={height/this.state.rowCount - 1}
                width={width}
                overscanColumnCount={1}
                scrollToAlignment={"start"}
                className="hiddenScrollbar"
                id={"timelineScrollViewDiv-" + this.props.currentProject.refID}
                onScroll={(e)=>{this.handleScroll(e)}}
                onSectionRendered={this.onSectionRendered.bind(this)}
              />
            }}
            </AutoSizer>
          </div>
        </div>

      )
    }

    currentMonthDiv = (
      <div>
        <ReactTextTransition direction={this.state.changeDirection} inline text={this.state.month} className="medium-text" style={{width:'unset'}} />
        <span style={{marginLeft:'10px'}}/>
        <ReactTextTransition direction={this.state.changeDirection} inline text={this.state.year} className="medium-text" style={{width:'unset'}} />
      </div>
    )



    return (
      <div style={{width:'100%',height:'100%',position:'relative'}}>
        <div className="topToBottomGrid" style={{height:'100%', position:'relative',}}>
          <AutoSizer>
            {({ width, height }) => {
            return <Grid
              ref={(ref) => this.myGrid = ref}
              cellRenderer={this.cellRenderer.bind(this)}
              columnCount={columnCount}
              columnWidth={350}
              height={height}
              rowCount={this.state.rowCount}
              rowHeight={height/this.state.rowCount - 1}
              width={width}
              overscanColumnCount={1}
              scrollToAlignment={"start"}
              className="hiddenScrollbar"
              id={"timelineScrollViewDiv-" + this.props.currentProject.refID}
              onScroll={(e)=>{this.handleScroll(e)}}
              onSectionRendered={this.onSectionRendered.bind(this)}
            />
          }}
          </AutoSizer>
        </div>

      </div>

    )
  }
}

const TimelineScrollView = connect(
  mapStateToProps, mapDispatchToProps
)(ConnectedTimelineScrollView);
export default TimelineScrollView;
