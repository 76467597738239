import React, { Component } from "react";
import { connect } from "react-redux";
import { addArticle } from "../actions/index";
import BasicPage from "./BasicPage";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import { NavLink } from 'react-router-dom';

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ]
};

const mapStateToProps = (state, ownProps) => {
  return {
    portfolios: state.portfolios,
    change: state.updates["home"],
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addArticle: article => dispatch(addArticle(article))
  };
}

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ""
    };
  }

  createSlides() {

    if (this.props.portfolios == null || Object.values(this.props.portfolios).length < 1) {
      return null;
    }

    const portfoliosDiv = Object.values(this.props.portfolios).map(portfolio => {
      const onClick = () => {
        document.getElementById("welcomeTitle").scrollIntoView({ behavior: 'smooth' });
        // document.getElementById(portfolio.refID).scrollIntoView({ behavior: 'smooth' });
      }
      return portfolio.getMainPhoto({height:"100%"}, true, onClick);
    })


    return portfoliosDiv
  }

  getPortfolioSections() {
    const portfolios = Object.values(this.props.portfolios);
    var portfolioSections = null;
    if (portfolios != null) {
      portfolioSections = portfolios.map(portfolio => {
        var imageDiv = (
          <div style={{float:'left', position:'relative', marginRight:'20px', }}>
            <NavLink to={"/portfolio/" + portfolio.visibleId} className="fillParent blurOverlay clickable" style={{position:'absolute', textAlign:'center', zIndex:5}}>
              <div className="centralizedDiv" style={{color:'white'}}>
                <div className="huge-text">
                  {portfolio.title}
                </div>
                <div>
                  view all stories
                </div>
              </div>

            </NavLink>
          <div className="color blur" style={{maxHeight:'300px', maxWidth:'400px', borderRadius: '8px', overflow:'hidden'}}>
            {portfolio.getMainPhoto({maxHeight:'300px', height: 'auto', width: 'auto', verticalAlign:'bottom'})}
          </div>

        </div>

        )

        var projectsDiv = null;

        if (portfolio.projects != null) {
          projectsDiv = Object.values(portfolio.projects).map(project => {


            return (
              <div className="medium-text" style={{display:'flex', alignItems:'center'}}>
                <NavLink className="medium-text" to={"/portfolio/" + portfolio.visibleId + "#" + project.visibleId} style={{textAlign:'left', position:'relative'}}>
                  {project.title}
                </NavLink>
                <span className="small-text" style={{textAlign:'left', position:'relative', marginLeft:'10px', fontSize:14, letterSpacing:0, verticalAlign:'middle'}}>
                  {project.subtitle}
                </span>
              </div>
            )
          })
        }

        return (
          <div key={portfolio.refID} id={portfolio.refID} style={{marginTop:'100px', position:'relative'}}>
            <div
                style={{
                    height: '300px',
                    width:'100%',
                }}
            >
              {portfolio.getMainPhoto({maxHeight:'300px', height: 'auto', width: 'auto', verticalAlign:'bottom'})}
            </div>
            <div style={{maxWidth:'90%', width:'1000px', position:'relative', borderRadius:'12px', overflow:'hidden', backgroundColor:'white', padding:'10px'}}>
              <div className="medium-text" style={{textAlign:'left', width:'100%', overflow:'auto', whiteSpace:'pre-wrap'}}>
                <NavLink className="huge-text" to={"/portfolio/" + portfolio.visibleId} style={{textAlign:'center', position:'relative', width:'intrinsic', margin:'auto', paddingLeft:'40px', paddingRight:'40px', paddingBottom:'15px', display:'block', borderBottom:'1px solid black'}}>
                  {portfolio.title}
                </NavLink>



                <br />

                <div className="clearfix" style={{position:'relative', padding:'5px'}}>
                  {imageDiv}
                  <div>
                    <div className="bold" style={{textAlign:'left', letterSpacing:'0px', fontSize:'20px'}}>
                      {portfolio.subtitle}
                    </div>

                    <div style={{textAlign:'left', letterSpacing:'0px', fontSize:'17px'}}>
                      {portfolio.description}
                    </div>
                    <br />
                    {projectsDiv}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })
    }
    return portfolioSections;
  }

  getPortfolioSectionsGrid() {
    const portfolios = Object.values(this.props.portfolios);
    var portfolioSections = null;
    if (portfolios != null) {
      portfolioSections = portfolios.map(portfolio => {
        return (
          <div key={portfolio.refID} id={portfolio.refID} style={{position:'relative', margin:'30px', flexGrow:1, width:'calc(25% - 10px)', minWidth: '240px'}}>
            <NavLink to={"/portfolio/" + portfolio.visibleId} className="fillParent blurOverlay clickable" style={{position:'absolute', textAlign:'center', zIndex:5}}>
              <div className="centralizedDiv" style={{color:'white'}}>
                <div className="huge-text">
                  {portfolio.title}
                </div>
                <div>
                  {portfolio.subtitle}
                </div>
              </div>
            </NavLink>
          <div className="color blur" style={{borderRadius: '8px', overflow:'hidden', height:'300px'}}>
            {portfolio.getMainPhoto({maxHeight:'300px', height: 'auto', width: 'auto', verticalAlign:'bottom'})}
          </div>
        </div>
      );
    });
    }
    return portfolioSections;
  }

  render() {
    var page = null;

    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const loadingScreen = (
      <div style={{backgroundColor:"black", color:'white', textAlign:'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%'}} >
        <div style={{flex:"0 0 300px",}} >
          Welcome to my life.
        </div>
      </div>
    )

    const slides = this.createSlides();
    var slider = loadingScreen;

    if (slides != null) {
      slider = (
        <AutoplaySlider
          play={true}
          cancelOnInteraction={false} // should stop playing on user interaction
          interval={6000}
          bullets={false}
          fillParent={true}
          startupScreen={loadingScreen}
        >
          {slides}
        </AutoplaySlider>
      );
    }

    var logIn = (
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
    )

    if (true) {
      logIn = null;
    }

    page = (
      <center style={{position:'relative'}}>
        <div style={{height:'calc(min(65vh,80vw))', width:'100%', position:'relative', backgroundColor:'black', zIndex:5}}>
          {slider}
        </div>
        <center style={{maxWidth:'90%', width:'800px', paddingTop:'50px', paddingBottom:'50px', position:'relative', backgroundColor:'white'}}>
          <div className="huge-text" style={{fontSize:'44px', fontWeight:'bold'}} id="welcomeTitle">
             Welcome to my life story
          </div>
          <br/>
          <br/>
          <div style={{lineHeight:'30px', fontSize:'20px'}}>
             There are so many people in the world, and the odds of my story being interesting enough to make it into a history book are slim. This website is mainly to immortalize my existence and make me feel more important than I probably am.
          </div>
        </center>
        <div style={{display:'inline-flex', width:'90%', maxWidth:'1400px', flexWrap:'wrap', marginBottom:'50px'}}>
          {this.getPortfolioSectionsGrid()}
        </div>
        {logIn}
      </center>
    )

    return (
      <BasicPage topMargin={"0px"} page={page} hideHeader={true}>
      </BasicPage>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
