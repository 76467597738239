import React, { Component } from "react";
import { connect } from "react-redux";
import { selectProject } from "../actions/index";
import Dropzone from 'react-dropzone';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import DatePicker from 'react-datepicker';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { HuePicker } from 'react-color';
import { Picture } from "../classes/Picture";

import "react-datepicker/dist/react-datepicker.css";

const FirebaseManager = require("../classes/FirebaseManager.js");

const mapStateToProps = state => {
  return {
    selectedProject: state.selectedProject,
    portfolios: state.portfolios,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    selectProject: project => dispatch(selectProject(project))
  };
}


class ProjectEditor extends Component {
  constructor(props) {
    super(props);
    var title = "";
    var description = "";
    var subtitle = "";
    var position = "";
    var website = "";
    var visibleId = "";
    var color = "white"
    var selectedTags = [];

    if (props.selectedProject.title != null) {
      title = props.selectedProject.title;
    }
    if (props.selectedProject.description != null) {
      description = props.selectedProject.description;
    }
    if (props.selectedProject.subtitle != null) {
      subtitle = props.selectedProject.subtitle;
    }
    if (props.selectedProject.position != null) {
      position = props.selectedProject.position;
    }
    if (props.selectedProject.website != null) {
      website = props.selectedProject.website;
    }
    if (props.selectedProject.visibleId != null) {
      visibleId = props.selectedProject.visibleId;
    }
    if (props.selectedProject.color != null) {
      color = props.selectedProject.color;
    }
    if (props.selectedProject.taggedPortfolios != null) {
      selectedTags = props.selectedProject.taggedPortfolios;
    }

    this.state = {
      title: title,
      description: description,
      subtitle: subtitle,
      website: website,
      position: position,
      visibleId: visibleId,
      color: color,
      selectedTags: selectedTags,
      changed: false,
      timestamp: 0,
    };
  }

  handleSelectChange(event) {
    this.setState({
      selectedTags: event.target.value
    })
  }

  syncLocalProjectDetails() {
    this.props.selectedProject.title = this.state.title;
    this.props.selectedProject.description = this.state.description;
    this.props.selectedProject.subtitle = this.state.subtitle;
    this.props.selectedProject.website = this.state.website;
    this.props.selectedProject.position = this.state.position;
    this.props.selectedProject.visibleId = this.state.visibleId;
  }

  saveProject() {
    this.syncLocalProjectDetails()

    if (this.state.selectedTags.length > 0) {
      this.props.selectedProject.taggedPortfolios = this.state.selectedTags;
    }


    this.props.selectedProject.color = this.state.color;
    if (this.state.timestamp > 0) {
      this.props.selectedProject.timestamp = this.state.timestamp;
    }

    this.props.selectedProject.saveToDatabase(refId => {
      const portfolios = Object.values(this.props.portfolios);
      for (var i = 0; i < portfolios.length; i++) {
        const portfolio = portfolios[i];
        if (portfolio.projectIds.indexOf(this.props.selectedProject.refID) !== -1 && this.props.selectedProject.taggedPortfolios.indexOf(portfolio.refID) === -1) {
          var oldSetOfProjectIds = portfolio.projectIds;
          oldSetOfProjectIds = oldSetOfProjectIds.filter(e => e !== this.props.selectedProject.refID);
          portfolio.projectIds = oldSetOfProjectIds;
          FirebaseManager.sharedInstance().saveIfPossible(portfolio);
        } else if (this.props.selectedProject.taggedPortfolios != null && this.props.selectedProject.taggedPortfolios.indexOf(portfolio.refID) > -1 && portfolio.projectIds.indexOf(portfolio.refID) === -1) {
          portfolio.projectIds.push(this.props.selectedProject.refID);
          FirebaseManager.sharedInstance().saveIfPossible(portfolio)
        }
      }
    })
  }

  deleteProject() {
    this.syncLocalProjectDetails();

    var oldPortfolios = this.props.selectedProject.taggedPortfolios;
    this.props.selectedProject.taggedPortfolios = [];

    this.props.selectedProject.color = this.state.color;
    if (this.state.timestamp > 0) {
      this.props.selectedProject.timestamp = this.state.timestamp;
    }

    this.props.selectedProject.saveToDatabase(refId => {
      const portfolios = Object.values(this.props.portfolios);
      for (var i = 0; i < portfolios.length; i++) {
        const portfolio = portfolios[i];
        if (portfolio.projectIds.indexOf(this.props.selectedProject.refID) !== -1 && this.props.selectedProject.taggedPortfolios.indexOf(portfolio.refID) === -1) {
          var oldSetOfProjectIds = portfolio.projectIds;
          oldSetOfProjectIds = oldSetOfProjectIds.filter(e => e !== this.props.selectedProject.refID);
          portfolio.projectIds = oldSetOfProjectIds;
          FirebaseManager.sharedInstance().saveIfPossible(portfolio);
        } else if (this.props.selectedProject.taggedPortfolios.indexOf(portfolio.refID) > -1 && portfolio.projectIds.indexOf(portfolio.refID) === -1) {
          portfolio.projectIds.push(this.props.selectedProject.refID);
          FirebaseManager.sharedInstance().saveIfPossible(portfolio);
        }
      }
      this.props.selectProject(null)
    })
  }

  handleChangeComplete = (color) => {
    this.setState({ color: color.hex });
  };

  onDrop(acceptedFiles, rejectedFiles) {
    var firstPicture = null;

    this.saveProject()

    acceptedFiles.forEach(file => {
      const randomID = Math.random().toString(36).substring(2,22);
      const url = URL.createObjectURL(file);

      var newPicture;
      if (firstPicture == null) {
        firstPicture = randomID;

        if (this.props.selectedProject.picture != null) {
          newPicture = this.props.selectedProject.picture;
          newPicture.deletePictureDataFromStorage();
        } else {
          newPicture = new Picture();
        }


        newPicture.project = this.props.selectedProject.project
        if (newPicture.pictureData != null) {
          newPicture.pictureData.imageURL = url;
        } else {
          newPicture.pictureData = {imageURL:url};
        }

        if (newPicture.refID == null) {
          newPicture.saveToDatabase();
        }

        this.props.selectedProject.picture = newPicture;

        this.props.selectProject(this.props.selectedProject);
        this.setState({changed:!this.state.changed});
      } else {
        const newPictureData = {pictureData:{imageURL:url}};
        newPicture = new Picture(newPictureData);
        newPicture.project = this.props.selectedProject.project

        newPicture.saveToDatabase();
      }


      const self = this;
      function callback(picture) {
        if (picture instanceof Picture) {
          if (self.props.selectedProject.refID != null) {
            if (newPicture.taggedProjects == null) {
              newPicture.taggedProjects = {};
            }
            newPicture.taggedProjects[self.props.selectedProject.refID] = true;
          }
          if (firstPicture === randomID) {
            newPicture.pictureData = picture.pictureData;
            if (newPicture.pictureData.timestamp != null) {
              newPicture.timestamp = newPicture.pictureData.timestamp
            }


            newPicture.saveToDatabase();
            self.props.selectedProject.picture = newPicture;

            self.props.selectProject(self.props.selectedProject);
            self.setState({changed:!self.state.changed, timestamp:newPicture.getTimestamp()})

          } else {
            newPicture.pictureData = picture.pictureData;
            if (newPicture.pictureData.timestamp != null) {
              newPicture.timestamp = newPicture.pictureData.timestamp
            }
            newPicture.saveToDatabase();
          }
        }
      }
      FirebaseManager.sharedInstance().uploadImage(file, callback);
    });
  }

  typeHandler(e) {
    switch (e.target.id) {
      case "title":
      case "description":
      case "subtitle":
      case "position":
      case "website":
      case "visibleId":
        this.setState({[e.target.id]:e.target.value})
        break;
      default:
        return;
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selectedProject != null && prevState.timestamp === 0) {

      var title = "";
      var description = "";
      var taggedProjects = [];
      var timestamp = 0;

      if (nextProps.selectedProject.getTimestamp() !== 0) {
        timestamp = nextProps.selectedProject.getTimestamp();
      }

      var newState =  {
        timestamp: timestamp
      }
      return newState;
    }

    return null;
  }

  render() {

    var title = "hello";

    if (this.props.title != null) {
      title = this.props.title;
    }

    var tags = [];

    for (var key in this.props.portfolios) {
      if (this.props.portfolios.hasOwnProperty(key)) {
        const portfolio = this.props.portfolios[key];
        tags.push({refID:portfolio.refID, title:portfolio.title});
      }
    }

    var dropzoneInnerDiv = (
      <div className="small-text" style={{height:'400px',lineHeight:'400px', backgroundColor:'rgb(200, 200, 200)'}}>
        Drag photo or click here to upload.
      </div>
    );

    if (this.props.selectedProject.picture != null && this.props.selectedProject.picture.getImageURL) {
      dropzoneInnerDiv = (
        <img src={this.props.selectedProject.picture.getImageURL()} className="small-text" width="300px" style={{width: "100%",height: "400px",objectFit:'cover',objectPosition:'center'}} />
      );
    }

    var startDate = new Date(this.state.timestamp);

    if (this.state.timestamp === 0) {
      startDate = new Date();
    }

    var colorAndTags = null;

    if (true) {
      colorAndTags = (
        <div>
          <FormControl style={{width:'100%'}}>
            <InputLabel id="demo-mutiple-name-label">Portfolios</InputLabel>
            <Select
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              multiple
              value={this.state.selectedTags}
              onChange={this.handleSelectChange.bind(this)}
              style={{width:'100%'}}
              input={<Input />}
            >
              {tags.map(tag => (
                <MenuItem key={tag.refID} value={tag.refID}>
                  {tag.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )
    }

    return (
      <div className="fullscreen darkOverlay" style={{textAlign:'center', display:'flex', zIndex:200}}>
        <div className="fullscreen" onClick={()=>{this.props.selectProject(null)}} />
        <div style={{margin:'auto', position:'relative', maxHeight:'80%', maxWidth:'90%', overflow:'scroll', width:'600px', backgroundColor:"rgb(255, 255, 255)"}} >
            <Dropzone onDrop={this.onDrop.bind(this)} accept="image/jpeg, image/png" style={{width:'100%', height:'400px'}}>
              {({getRootProps, getInputProps}) => (
                <section style={{width: "100%",height: "400px",verticalAlign:'middle'}}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {dropzoneInnerDiv}
                  </div>
                </section>
              )}
            </ Dropzone>
            <form noValidate autoComplete="off" style={{padding:'8px'}}>
              <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <TextField
                    onChange={this.typeHandler.bind(this)}
                    id="title"
                    label="Title"
                    defaultValue={this.props.selectedProject.title}
                    margin="normal"
                    variant="outlined"
                    style={{width: '60%'}}
                  />
                <div style={{marginRight: '40px'}}>
                    <DatePicker
                      selected={startDate}
                      onChange={date => this.setState({timestamp:date.getTime()})}
                      timeFormat="HH:mm"
                      popperPlacement="bottom-begin"
                      showYearDropdown
                      dateFormat="MMM d, yyyy h:mm aa"

                    />
                </div>
              </div>

              <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <TextField
                    onChange={this.typeHandler.bind(this)}
                    id="subtitle"
                    label="Subtitle"
                    defaultValue={this.props.selectedProject.subtitle}
                    margin="normal"
                    variant="outlined"
                    style={{width: '40%'}}
                  />
                <TextField
                    onChange={this.typeHandler.bind(this)}
                    id="visibleId"
                    label="Visible Id"
                    defaultValue={this.props.selectedProject.visibleId}
                    margin="normal"
                    variant="outlined"
                    style={{width: '40%'}}
                  />
              </div>
              <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <TextField
                    onChange={this.typeHandler.bind(this)}
                    id="position"
                    label="Role"
                    defaultValue={this.props.selectedProject.position}
                    margin="normal"
                    variant="outlined"
                    style={{width: '40%'}}
                  />
                <TextField
                    onChange={this.typeHandler.bind(this)}
                    id="website"
                    label="Website"
                    defaultValue={this.props.selectedProject.website}
                    margin="normal"
                    variant="outlined"
                    style={{width: '40%'}}
                  />
              </div>
              <div>
                <TextField
                  onChange={this.typeHandler.bind(this)}
                  id="description"
                  label="Description"
                  defaultValue={this.props.selectedProject.description}
                  multiline
                  margin="normal"
                  variant="outlined"
                  style={{width: '100%'}}
                />
              </div>

              <br />
              {colorAndTags}
              <br />
              <br />
              <span>
                <Button variant="contained" color="primary" style={{backgroundColor:'rgb(161, 5, 5)', width:'40%', margin:'5px'}} onClick={this.deleteProject.bind(this)}>
                  Delete
                </Button>
              </span>
              <span>
                <Button variant="contained" color="primary" style={{backgroundColor:'rgb(26, 149, 68)', width:'40%', margin:'5px'}} onClick={this.saveProject.bind(this)}>
                  Save
                </Button>
              </span>
            </form>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEditor);
