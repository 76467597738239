import * as firebase from 'firebase';
import React, { Component } from "react";
import 'firebase/firestore';
import $ from "jquery";
import store from "../store/index";
import { Picture } from "./Picture";
import { Achievement } from "./Achievement";
import { Portfolio } from "./Portfolio";
import { Project } from "./Project";
import { DatabaseObject } from "./DatabaseObject";
import loadImage from 'blueimp-load-image'
import { allPictures, allAchievements, allProjects, allPortfolios, achievementColors, selectAchievement } from "../actions/index";

var moment = require('moment');
const parse = require('html-react-parser');

function ConvertDMSToDD(degrees, minutes, seconds, direction) {
    var dd = degrees + minutes/60 + seconds/(60*60);

    if (direction == "S" || direction == "W") {
        dd = dd * -1;
    }
    return dd;
}

class FirebaseManager {
  constructor() {

    var firebaseConfig = {
      apiKey: "AIzaSyCnslMchKbG79G9I67raDn5vPxkm0Pq5-4",
      authDomain: "jack-connolly.firebaseapp.com",
      databaseURL: "https://jack-connolly.firebaseio.com",
      projectId: "jack-connolly",
      storageBucket: "jack-connolly.appspot.com",
      messagingSenderId: "901455958401",
      appId: "1:901455958401:web:b5d64c732168f472a51880",
      measurementId: "G-JJVHPHY4JH"
    };
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    this.dev = false;



    firebase.auth().onAuthStateChanged(firebaseUser => {
      if (firebaseUser) {
        this.user = firebaseUser;
        this.dev = firebaseUser.email === "jackjosephconnolly@gmail.com";
        store.dispatch(selectAchievement(null));
      } else {
        this.user = null;
      }
      const widget = document.getElementById('bmc-wbtn');
      if (widget != null) {
        if (this.dev === true) {
          widget.style.opacity = 0;
        } else {
          widget.style.opacity = 1;
        }
      }

    });

    this.picturesRef = firebase.firestore().collection(`pictures/`);
    this.achievementsRef = firebase.firestore().collection(`achievements/`);
    this.portfoliosRef = firebase.firestore().collection(`portfolios/`);
    this.projectsRef = firebase.firestore().collection(`projects/`);
    this.getTimelinePictures();
    this.getAchievements();
    this.getProjects();
    this.getPortfolios();
  }

  parseLinksIntoText(text) {
    var alteredText = "<div>" + text + "</div>";

    while (alteredText.includes("<<")) {
      const startIndex = alteredText.indexOf("<<");
      const endIndex = alteredText.indexOf(">>");
      const beginPart = alteredText.substring(0, startIndex);
      const endPart = alteredText.substring(endIndex + 2);

      const parsingText = alteredText.substr(startIndex + 2, endIndex);
      const linkEnd = parsingText.indexOf(" ");
      const link = parsingText.substring(0, linkEnd);
      const text = parsingText.substring(linkEnd + 1, parsingText.indexOf(">>"));

      const parsedLink = "<a href='" + link + "'>" + text + "</a>"
      alteredText = beginPart + parsedLink + endPart
    }

    return this.parseTextIntoHtml(alteredText)
  }

  parseTextIntoHtml(text) {
    return parse(text);
  }

  saveIfPossible(databaseObject) {
    if (databaseObject instanceof DatabaseObject) {
      databaseObject.saveToDatabase();
    }
  }

  deleteIfPossible(databaseObject) {
    if (databaseObject instanceof DatabaseObject) {
      databaseObject.deleteFromDatabase();
    }
  }

  randomId() {
    return Math.random().toString(36).substring(2,22);
  }

  uploadVideo(file, uploadCallback) {

    // Cancel if the initial filesize just seems too large.
    // If the maximum allowed is 30 seconds, then a Gigabyte of file seems too much.

    if( file.size > 100000000 ){
      console.log(file.size);
      console.log("error too large");
      return;

    }

    const reader = new FileReader();

    const randomID = Math.random().toString(36).substring(2,22);

    var pictureData = {};

    var video = document.createElement('video');
    var canvas = document.createElement('canvas');

    video.addEventListener('loadeddata', function() {
      console.log("loaded video");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight
      canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      var uploadingImage = new Image();
      uploadingImage.src = canvas.toBlob(function(blob) {
        firebase.storage().ref().child('pictures').child(randomID + "-thumbnail.jpg").put(blob).then(function(snapshot) {

          pictureData.thumbnailStorageURL = snapshot.metadata.fullPath;
          firebase.storage().ref(snapshot.metadata.fullPath).getDownloadURL().then(function (url) {
            pictureData.thumbnailURL = url;
            console.log("posted thumbnail");
            uploadCallback(new Picture({pictureData: pictureData}));
          });

        });
      }, 'image/jpeg', 0.85);
    });

    reader.addEventListener( 'loadend', function(){
        video.src = reader.result;

    });

    reader.readAsDataURL( file );

    pictureData.timestamp = file.lastModified;
    pictureData.type = "video";



    var uploadTask = firebase.storage().ref().child('videos/' + randomID).put(file);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function(snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      }, function(error) {

    }, function() {
      // Upload completed successfully, now we can get the download URL
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        pictureData.imageURL = downloadURL;
        pictureData.imageStorageURL = uploadTask.snapshot.metadata.fullPath;

        console.log('File available at', downloadURL);
        uploadCallback(new Picture({pictureData: pictureData}));
      });
    });

  }

  uploadImage(file, uploadCallback) {
    console.log("uploading picture")
    var img = new Image();
    var self = this;

    var loadImageOptions = { canvas: true }
    loadImage.parseMetaData(file, (data) => {
      var pictureData = {};
      pictureData.timestamp = file.lastModified;

      if (data.exif) {
        if (data.exif.get('Orientation')) {
          loadImageOptions.orientation = data.exif.get('Orientation');
          pictureData.orientation = loadImageOptions.orientation;
        }

        if (data.exif["1"] && data.exif["2"] && data.exif["3"] && data.exif["4"]) {
          const lat = data.exif["2"];
          const lon = data.exif["4"];


          const latitude = ConvertDMSToDD(lat[0], lat[1], lat[2], data.exif["1"]);
          const longitude = ConvertDMSToDD(lon[0], lon[1], lon[2], data.exif["3"]);

          pictureData.geolocation = {latitude:latitude, longitude:longitude};
        }

        if (data.exif["36867"]) {
          var tstamp = moment(data.exif["36867"], "YYYY:MM:DD HH:mm:ss");
          var timestamp = tstamp.valueOf();
          pictureData.timestamp = timestamp;
        }
      }

      const randomID = Math.random().toString(36).substring(2,22);
      var fileExtension = ".jpg";
      if (file.type.toLowerCase().includes("png") === true) {
        fileExtension = ".png"
      }

      loadImage(file, (canvas) => {
        var uploadingImage = new Image();
      	uploadingImage.src = canvas.toBlob(function(blob) {
          pictureData.height = canvas.height;
          pictureData.width = canvas.width;
          firebase.storage().ref().child('pictures').child(randomID + fileExtension).put(blob).then(function(snapshot) {


            pictureData.imageStorageURL = snapshot.metadata.fullPath;
            firebase.storage().ref(snapshot.metadata.fullPath).getDownloadURL().then(function (url) {
              pictureData.imageURL = url;
              uploadCallback(new Picture({pictureData: pictureData}));
            });

          });
        }, file.type, 0.85);
      }, loadImageOptions)

      var loadImageOptions2 = Object.assign({}, loadImageOptions);
      loadImageOptions2.maxWidth = 800;

      loadImage(file, (canvas) => {

        var uploadingImage = new Image();
      	uploadingImage.src = canvas.toBlob(function(blob) {
          firebase.storage().ref().child('pictures').child(randomID + '-400px' + fileExtension).put(blob).then(function(snapshot) {

            pictureData.smallImageStorageURL = snapshot.metadata.fullPath;
            firebase.storage().ref(snapshot.metadata.fullPath).getDownloadURL().then(function (url) {
              pictureData.smallImageURL = url;
              uploadCallback(new Picture({pictureData: pictureData}));
            });

          });
        }, file.type, 0.85);
      }, loadImageOptions2)


      var loadImageOptions3 = Object.assign({}, loadImageOptions);
      loadImageOptions3.maxWidth = 100;
      loadImageOptions3.maxHeight = 100;

      loadImage(file, (canvas) => {

        var uploadingImage = new Image();
      	uploadingImage.src = canvas.toBlob(function(blob) {
          firebase.storage().ref().child('pictures').child(randomID + '-50px' + fileExtension).put(blob).then(function(snapshot) {

            pictureData.tinyImageStorageURL = snapshot.metadata.fullPath;
            firebase.storage().ref(snapshot.metadata.fullPath).getDownloadURL().then(function (url) {
              pictureData.tinyImageURL = url;
              uploadCallback(new Picture({pictureData: pictureData}));
            });

          });
        }, file.type, 0.85);
      }, loadImageOptions3)
    })
  }

  getTimelinePictures() {
    this.picturesRef.orderBy("timestamp", "asc").onSnapshot(function(querySnapshot) {
      const pictures = querySnapshot.docs.map(doc => {
        var picture = doc.data();
        picture.refID = doc.id;
        return new Picture(picture);
      });
      store.dispatch(allPictures(pictures));
    });

  }



  getProjects() {
    const self = this;
    this.projectsRef.onSnapshot(function(querySnapshot) {
      const projects = querySnapshot.docs.map(doc => {
        var project = doc.data();
        project.refID = doc.id;
        const state = store.getState();
        if (state.projects != null && project.visibleId != null && state.projects[project.visibleId] != null) {
          const oldProject = state.projects[project.visibleId];
          oldProject.deserialize(project);
          return oldProject;
        }
        const projectObject = new Project(project);

        return projectObject;
      });
      store.dispatch(allProjects(projects));
    });

  }

  getAchievements() {
    const self = this;
    this.achievementsRef.onSnapshot(function(querySnapshot) {
      var achievementColorMaps = {};
      const achievements = querySnapshot.docs.map(doc => {
        var achievement = doc.data();
        achievement.refID = doc.id;
        if (achievement.color != null) {
          achievementColorMaps[doc.id] = achievement.color;
        }

        const achievementObject = new Achievement(achievement);
        return achievementObject;
      });
      store.dispatch(allAchievements(achievements));
      store.dispatch(achievementColors(achievementColorMaps))
    });

  }

  getPortfolios() {
    const self = this;
    this.portfoliosRef.onSnapshot(function(querySnapshot) {
      const portfolios = querySnapshot.docs.map(doc => {
        var portfolio = doc.data();
        portfolio.refID = doc.id;
        const portfolioObject = new Portfolio(portfolio);
        return portfolioObject;
      });
      store.dispatch(allPortfolios(portfolios));
    });

  }
}

class StaticFirebaseManager {
  constructor() {
    this.staticInstance = new FirebaseManager()
  }
}

export function createSharedInstance() {
  this.shared = new StaticFirebaseManager();
}

export function sharedInstance() {
  return this.shared.staticInstance;
}
