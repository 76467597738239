import React, { Component } from "react";
import { Image } from "./Image";


function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}


export class PictureRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ""
    };
    this.pictureArrays = [];
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var newState = {};
    if (nextProps.pictures != null) {
      if (prevState.pictures == null || prevState.pictures.length !== nextProps.pictures.length) {
        newState.pictures = nextProps.pictures;
        if (nextProps.overflow === true) {
          var pictures = nextProps.pictures;
          var pictureCounter = 0;
          var pictureArrays = [];
          while (pictures.length - pictureCounter > 0 && (nextProps.maxRows == null ||  pictureArrays.length < nextProps.maxRows)) {
            const rowLength = 3 + getRandomInt(4);
            pictureArrays.push(pictures.slice(pictureCounter, pictureCounter + rowLength))
            pictureCounter += rowLength;
          }
          newState.pictureArrays = pictureArrays;
        }
      }
    }
    return newState;
  }

  render() {

    var pictures = this.props.pictures;
    var lengthInPixels = "400px";
    if (this.props.lengthInPixels != null) {
      lengthInPixels = this.props.lengthInPixels;
    }
    if (pictures == null) {
      return null;
    }

    var maxPerRow = 6;
    if (this.props.maxElements != null) {
      maxPerRow = this.props.maxElements;
    }
    if (maxPerRow != null && pictures.length > maxPerRow && this.props.overflow !== true) {
      if (this.props.reverse) {
        pictures = pictures.slice(-maxPerRow);
      } else {
        pictures = pictures.slice(0, maxPerRow);
      }

    }

    if (this.props.reverse) {
      pictures = pictures.reverse();
    }

    var pictureArrays = this.state.pictureArrays

    if (this.props.overflow !== true) {
      var counter = 0;
      const picturesRow = pictures.map(picture => {
        counter += 1;


        if (maxPerRow != null && this.props.pictures.length > maxPerRow && counter === maxPerRow) {
          const leftOver = this.props.pictures.length - maxPerRow;
          return (
            <span key={picture.refID} style={{height:'100%', width:"" + 100 / pictures.length + "%", maxWidth:"20%", margin:"1px", position:'relative'}} onClick={()=>{this.props.clickPicture(picture)}}>
              <Image lowQuality={true} picture={picture} style={{height:'100%', width:'100%',objectFit:'cover',objectPosition:'center'}}/>
              <div className="fillParent" style={{position:'absolute', backgroundColor:'rgba(0,0,0,0.7)', color:"white", textAlign:'center', fontWeight:'bold', display:'table'}}>
                <div style={{display:'table-cell', verticalAlign:'middle'}}>
                  +{leftOver}
                </div>
              </div>
            </span>
          )
        }

        return (
          <span key={picture.refID} className="clickable" style={{height:'100%', width:"" + 100 / pictures.length + "%", maxWidth:"20%", padding:"1px"}} onClick={()=>{this.props.clickPicture(picture)}}>
            <Image lowQuality={true} picture={picture} style={{height:'100%', width:'100%',objectFit:'cover',objectPosition:'center'}}/>
          </span>
        )
      })
      var addedStyles = {};
      if (this.props.reverse) {
        addedStyles.flexDirection = "row-reverse"
      }
      return (
        <div style={{height:'100%', width:'100%', position:'relative', display:'flex', ...addedStyles}}>
          {picturesRow}
        </div>
      );
    }
    var counter = 0
    const pictureRows = pictureArrays.map(pictureArray => {
      counter += 1;
      const picturesRow = pictureArray.map(picture => {

        return (
          <span key={picture.refID} className="clickable" style={{height:'100%', width:"" + 100 / pictureArray.length + "%", maxWidth:"34%", padding:"1px", cursor:'pointer'}}
            onClick={()=>{this.props.clickPicture(picture)}}
            onMouseEnter={() => this.setState({hoveredImage: picture})}
            onMouseLeave={() => this.setState({hoveredImage: null})}>
            <Image lowQuality={true} picture={picture} style={{height:'100%', width:'100%',objectFit:'cover',objectPosition:'center'}}/>
          </span>
        )
      })

      return (
        <div key={counter} style={{height:'60px', width:'100%', position:'relative', display:'flex', marginTop: '2px',}}>
          {picturesRow}
        </div>
      );
    })

    if (this.props.showcasePhoto != null) {
      var photo = this.props.showcasePhoto;
      if (this.state.hoveredImage != null) {
        photo = this.state.hoveredImage;
      }
      return (
        <div>
          <Image picture={photo} mediumQuality={true} fancyLoad={true} style={{height:lengthInPixels, width:lengthInPixels, objectFit:'cover', objectPosition:'center'}}/>
          <div style={{width:lengthInPixels}}>
            {pictureRows}
          </div>
        </div>
      )
    }

    return pictureRows;

  }
}
