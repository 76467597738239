import React, { Component } from "react";
import { connect } from "react-redux";
import { selectPicture } from "../actions/index";
import Dropzone from 'react-dropzone';
import DatePicker from 'react-datepicker';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Picture } from "../classes/Picture";
import { Image } from "./Image";

import "react-datepicker/dist/react-datepicker.css";

const FirebaseManager = require("../classes/FirebaseManager.js");

const mapStateToProps = state => {
  return {
    selectedPicture: state.selectedPicture,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    selectPicture: picture => dispatch(selectPicture(picture))
  };
}


class PhotoEditor extends Component {
  constructor(props) {
    super(props);
    var title = "";
    var description = "";
    var taggedAchievements = [];

    var fieldsToSave = {
      horizontalAlign: null,
      verticalAlign: null,
    };

    this.state = {
      title: title,
      description: description,
      taggedAchievements : taggedAchievements,
      changed : false,
      timestamp: 0,
      showFullPicture: false,
      fieldsToSave: fieldsToSave
    };
  }

  handleSelectChange(event) {
    this.setState({
      taggedAchievements: event.target.value
    })
  }

  saveCurrentPhoto() {
    if (this.state.timestamp > 0) {
      this.props.selectedPicture.timestamp = this.state.timestamp;
    }
    this.savePhoto(this.props.selectedPicture)
  }

  savePhoto(picture) {


    for (var field in this.state.fieldsToSave) {
      if (this.state.fieldsToSave.hasOwnProperty(field)) {
        picture[field] = this.state.fieldsToSave[field];
      }
    }


    picture.title = this.state.title;
    picture.description = this.state.description;

    const taggedAchievementsDict = {};
    for (var achievementKey in this.state.taggedAchievements) {
      if (this.state.taggedAchievements.hasOwnProperty(achievementKey)) {
        taggedAchievementsDict[this.state.taggedAchievements[achievementKey]] = true;
      }
    }

    picture.taggedAchievements = taggedAchievementsDict;


    FirebaseManager.sharedInstance().saveIfPossible(picture);
  }

  deletePhoto() {
    FirebaseManager.sharedInstance().deleteIfPossible(this.props.selectedPicture);
    this.props.selectPicture(null)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selectedPicture != null) {

      if (nextProps.selectedPicture.refID == null) {
        return {pictureRefID: "new"}
      }

      if (nextProps.selectedPicture.refID === prevState.pictureRefID) {
        return null
      }

      if (prevState.pictureRefID === "new") {
        return {pictureRefID: nextProps.selectedPicture.refID}
      }

      var title = "";
      var description = "";
      var taggedAchievements = [];
      var timestamp = 0;

      if (nextProps.selectedPicture.title != null) {
        title = nextProps.selectedPicture.title;
      }
      if (nextProps.selectedPicture.description != null) {
        description = nextProps.selectedPicture.description;
      }
      if (nextProps.selectedPicture.taggedAchievements != null) {
        taggedAchievements = Object.keys(nextProps.selectedPicture.taggedAchievements);
      }
      if (nextProps.selectedPicture.timestamp !== 0) {
        timestamp = nextProps.selectedPicture.getTimestamp();
      }






      var newState =  {
        pictureRefID: nextProps.selectedPicture.refID,
        description: description,
        title: title,
        taggedAchievements: taggedAchievements,
        timestamp: timestamp
      }

      for (var field in prevState.fieldsToSave) {
        if (prevState.fieldsToSave.hasOwnProperty(field) && nextProps.selectedPicture.hasOwnProperty(field)) {
          newState[field] = nextProps.selectedPicture[field];
        }
      }
      return newState;
    }

    return null;
  }

  handlePicture(file) {

  }

  onDrop(acceptedFiles, rejectedFiles) {
    var firstFile = null;

    if (acceptedFiles.length > 0) {
      firstFile = acceptedFiles[0];
    }

    acceptedFiles.forEach(file => {


      const url = URL.createObjectURL(file);

      var newPicture;
      if (firstFile == file) {

        newPicture = this.props.selectedPicture;
        newPicture.deletePictureDataFromStorage();

        if (newPicture.pictureData != null) {
          newPicture.pictureData.imageURL = url;
        } else {
          newPicture.pictureData = {imageURL:url};
        }

        newPicture.project = this.props.selectedPicture.project

        if (newPicture.refID == null) {
          this.savePhoto(newPicture);
        }

        this.props.selectPicture(newPicture);
        this.setState({changed:!this.state.changed});
      } else {
        const newPictureData = {pictureData:{imageURL:url}};
        newPicture = new Picture(newPictureData);
        newPicture.project = this.props.selectedPicture.project
        this.savePhoto(newPicture);
      }


      const self = this;
      function callback(picture) {
        if (picture instanceof Picture) {
          if (firstFile == file) {
            newPicture.pictureData = picture.pictureData;
            if (newPicture.pictureData.timestamp != null) {
              newPicture.timestamp = newPicture.pictureData.timestamp
            }
            self.savePhoto(newPicture);

            self.props.selectPicture(newPicture);
            self.setState({changed:!self.state.changed, timestamp:newPicture.getTimestamp()})

          } else {
            newPicture.pictureData = picture.pictureData;
            if (newPicture.pictureData.timestamp != null) {
              newPicture.timestamp = newPicture.pictureData.timestamp
            }
            self.savePhoto(newPicture);
          }
        }
      }
      if (file.type.includes("video") === true) {
        FirebaseManager.sharedInstance().uploadVideo(file, callback);
      } else {
        FirebaseManager.sharedInstance().uploadImage(file, callback);
      }

    });
  }

  typeHandler(e) {
    switch (e.target.id) {
      case "title":
        this.setState({title:e.target.value})
        break;
      case "description":
        this.setState({description:e.target.value})
        break;
      default:
        return;
    }
  }

  setFieldToSave(field, value) {
    var currentFieldsToSave = Object.assign({}, this.state.fieldsToSave);
    currentFieldsToSave[field] = value;
    this.setState({fieldsToSave:currentFieldsToSave})
  }

  render() {

    var title = "hello";

    if (this.props.title != null) {
      title = this.props.title;
    }

    var dropzoneInnerDiv = (
      <div className="small-text" style={{height:'400px', width:'500px',lineHeight:'400px', backgroundColor:'rgb(200, 200, 200)'}}>
        Drag photo or click here to upload.
      </div>
    );

    var innerStyle = {};
    var mainStyle = {};
    var detailsStyle = {};

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
      innerStyle.width = '100%'
      detailsStyle.width = '100%'
    } else {
      mainStyle.display = 'flex'
      mainStyle.flexWrap = 'wrap'
    }


    if (this.props.selectedPicture != null && this.props.selectedPicture.getSmallImageURL() !== "") {
      var maxHeight = '700px';
      if (this.state.showFullPicture === false) {
        maxHeight = '400px';
      }
      dropzoneInnerDiv = (
        <Image src={this.props.selectedPicture.getSmallImageURL()} picture={this.props.selectedPicture} className="small-text" style={{width:'100%', objectFit:'cover', objectPosition:'center', ...innerStyle}} />
      );
    }

    var startDate = new Date(this.state.timestamp);

    if (this.state.timestamp === 0) {
      startDate = new Date();
    }

    var achievements = this.props.achievements;

    if (achievements == null) {
      if (this.props.selectedPicture.project != null && this.props.selectedPicture.project.achievements != null) {
        achievements = this.props.selectedPicture.project.achievements;
      } else {
        achievements = [];
      }
    }


    return (
      <div className="fullscreen darkOverlay" style={{textAlign:'center', display:'flex', zIndex:100000}}>
        <div className="fullscreen" onClick={()=>{this.props.selectPicture(null)}} />
        <div style={{margin:'auto', position:'relative', maxHeight:'80%', maxWidth:'90%', overflow:'scroll', backgroundColor:"rgb(255, 255, 255)", alignItems:'center', ...mainStyle}} >
            <Dropzone onDrop={this.onDrop.bind(this)} accept="image/jpeg, image/png, video/*" >
              {({getRootProps, getInputProps}) => (
                <section style={{verticalAlign:'middle', flexGrow:'1', width:'600px'}}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {dropzoneInnerDiv}
                  </div>
                </section>
              )}
            </ Dropzone>
            <div style={{height:'100%', overflow:'scroll', display:'inline', width:'440px', flexGrow:1, ...detailsStyle}}>
            <form noValidate autoComplete="off" style={{padding:'8px'}}>
              <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <TextField
                  onChange={this.typeHandler.bind(this)}
                  id="title"
                  label="Title"
                  value={this.state.title}
                  margin="normal"
                  variant="outlined"
                  style={{width: '60%'}}
                />
              <div style={{marginRight: '40px'}}>
                  <DatePicker
                    selected={startDate}
                    onChange={date => this.setState({timestamp:date.getTime()})}
                    timeFormat="HH:mm"
                    popperPlacement="bottom-begin"
                    showYearDropdown
                    dateFormat="MMM d, yyyy h:mm aa"

                  />
              </div>
              </div>
              <div>
                <TextField
                  onChange={this.typeHandler.bind(this)}
                  id="description"
                  label="Description"
                  value={this.state.description}
                  multiline
                  rows="4"
                  margin="normal"
                  variant="outlined"
                  style={{width: '100%'}}
                />
              </div>
              <FormControl style={{width:'100%'}}>
                <InputLabel id="demo-mutiple-name-label">Sections</InputLabel>
                <Select
                  labelId="demo-mutiple-name-label"
                  id="demo-mutiple-name"
                  multiple
                  value={this.state.taggedAchievements}
                  onChange={this.handleSelectChange.bind(this)}
                  style={{width:'100%'}}
                  input={<Input />}
                >
                  {achievements.map(achievement => (
                    <MenuItem key={achievement.refID} value={achievement.refID}>
                      {achievement.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <br />
              <div >
                <div style={{display:'flex', height:'80px', width:'200px'}}>
                  <span className="clickable" style={{width:'30%', height:'100%',}} onClick={()=>{
                      this.setFieldToSave("verticalAlign", "0%")}}
                      >
                   ⬆️
                  </span>
                  <span className="clickable" style={{width:'30%', height:'100%',}} onClick={()=>{
                      this.setFieldToSave("verticalAlign", "50%")}}
                      >
                    ↕️
                  </span>
                  <span className="clickable" style={{width:'30%', height:'100%',}} onClick={()=>{
                      this.setFieldToSave("verticalAlign", "100%")}}
                      >
                    ⬇️
                  </span>
                </div>
                <div style={{display:'flex', height:'80px', width:'200px'}}>
                  <span className="clickable" style={{width:'30%', height:'100%',}} onClick={()=>{
                      this.setFieldToSave("horizontalAlign", "0%")}}
                      >
                    ⬅️
                  </span>
                  <span className="clickable" style={{width:'30%', height:'100%',}} onClick={()=>{
                      this.setFieldToSave("horizontalAlign", "50%")}}
                      >
                    ↔️
                  </span>
                  <span className="clickable" style={{width:'30%', height:'100%',}} onClick={()=>{
                      this.setFieldToSave("horizontalAlign", "100%")}}
                      >
                    ➡️
                  </span>
                </div>
              </div>
              <br />
              <br />
              <span>
                <Button variant="contained" color="primary" style={{backgroundColor:'rgb(161, 5, 5)', width:'40%', margin:'5px'}} onClick={this.deletePhoto.bind(this)}>
                  Delete
                </Button>
              </span>
              <span>
                <Button variant="contained" color="primary" disabled={this.props.selectedPicture.pictureData == null} style={{backgroundColor:'rgb(26, 149, 68)', width:'40%', margin:'5px'}} onClick={this.saveCurrentPhoto.bind(this)}>
                  Save
                </Button>
              </span>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoEditor);
