import React, { Component } from "react";
import { connect } from "react-redux";
import { selectPicture, selectAchievement, setStateKeyValue } from "../actions/index";
import BasicPage from "./BasicPage";
import TimelineSidePanel from "./TimelineSidePanel";
import TimelineScrollView from "./TimelineScrollView";
import AchievementEditor from "./AchievementEditor";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import TimelinePage from "./TimelinePage";
import { Image } from "./Image";
import { PictureRow } from "./PictureRow";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const FirebaseManager = require("../classes/FirebaseManager.js");
var moment = require('moment');

const mapStateToProps = (state, ownProps) => {

  var projectVisibleId = null;
  if (ownProps != null && ownProps.match != null && ownProps.match.params != null) {
    projectVisibleId = ownProps.match.params.id;
  } else if (ownProps.currentProject != null) {
    projectVisibleId = ownProps.currentProject.visibleId;
  }
  return {
    currentProject: state.projects[projectVisibleId],
    change: state.updates[projectVisibleId],
    selectedAchievement: state.selectedAchievement,
    selectedPicture: state.selectedPicture,
  };
};

const collapseThreshhold = 4000;
const collapseBufferThreshhold = 800;

function mapDispatchToProps(dispatch) {
  return {
    selectPicture: picture => dispatch(selectPicture(picture)),
    setStateKeyValue: (key, value) => dispatch(setStateKeyValue(key, value)),
    selectAchievement: achievement => dispatch(selectAchievement(achievement))
  };
}

function isElementInViewport (el) {

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
}

class ProjectPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      currentProjectId:null,
      scrollToColumn:0,
      currentAchievement:null,
      currentAchievementRecieved:null,
      beginTimestamp:0,
      heightOfCover:0,
      collapseBuffer:0,
      endTimestamp: (new Date()).getTime()
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var newState = {};
    var pictures = [];
    if (nextProps.currentProject != null) {
      if (prevState.currentProjectId !== nextProps.currentProject.refID) {
        nextProps.setStateKeyValue("pageTitle", nextProps.currentProject.title)
      }
      newState.currentProjectId = nextProps.currentProject.refID;

      nextProps.currentProject.getPictures()
      nextProps.currentProject.getAchievements()

      if (nextProps.currentProject.pictures != null && nextProps.currentProject.achievements != null) {
        for (var achievement of nextProps.currentProject.achievements) {
          achievement.pictures = [];
        }
        for (var picture of nextProps.currentProject.pictures) {
          var addPicture = true;
          if (picture.taggedAchievements != null) {
            for (var achievementKey in picture.taggedAchievements) {
              for (var achievement of nextProps.currentProject.achievements) {
                if (achievement.refID === achievementKey) {
                  if (achievement.picture == null || achievement.picture.refID !== picture.refID) {
                    achievement.pictures.push(picture);
                  } else {
                    addPicture = false;
                  }
                }
              }
            }
          }
          if (addPicture === true || true) {
            pictures.push(picture)
          }
        }

      }
      if (nextProps.currentProject.achievements != null && nextProps.currentProject.achievements.length > 0) {
        if (prevState.currentAchievement == null) {
          newState.currentAchievement = nextProps.currentProject.achievements[0];
        }
        newState.beginTimestamp = (new Date()).getTime()
        newState.endTimestamp = 0;
        for (var i = 0; i < nextProps.currentProject.achievements.length; i++) {
          const achievement = nextProps.currentProject.achievements[i];
          newState.beginTimestamp = Math.min(achievement.getTimestamp(), newState.beginTimestamp);
          newState.endTimestamp = Math.max(achievement.getTimestamp(), newState.endTimestamp);
        }

      }

    }

    if (nextProps.currentAchievement != prevState.currentAchievementRecieved) {
      newState.currentAchievementRecieved = nextProps.currentAchievement;
    }

    newState.pictures = pictures;

    return newState;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentAchievement != this.props.currentAchievement) {
      setTimeout(function () {
          this.animateToAchievement(this.props.currentAchievement)
      }.bind(this), 400);
      this.setState({heightOfCover:0, collapseBuffer:0, stopScrolling: false})
    }
  }

  calculateTimePercentage(time) {
    const newWidth = Math.min(Math.max(Math.round((time - this.state.beginTimestamp) / (this.state.endTimestamp - this.state.beginTimestamp) * 100), 0), 100)
    return newWidth
  }

  setScrollBarWidth(percent) {
    this.scrollBarWidth = percent;
    const scrollbar = document.getElementById("customScrollBar-" + this.props.currentProject.refID);
    scrollbar.style.width = "" + this.scrollBarWidth + "%";
  }

  onPageScroll(e) {
    if (this.state.stopScrolling === true) {
      e.preventDefault();
      return;
    }
    if (this.props.selectedAchievement != null || this.props.selectedPicture != null) {
      return;
    }




    var delta = e.nativeEvent.wheelDelta;
    if (Math.abs(e.nativeEvent.wheelDeltaX) > Math.abs(e.nativeEvent.wheelDeltaY)) {
      delta = e.nativeEvent.wheelDeltaX;
    }

    const scrollbar = document.getElementById("endTimeline-" + this.props.currentProject.refID);
    const titleDiv = document.getElementById("titleDiv-" + this.props.currentProject.visibleId);

    if (scrollbar == null || titleDiv == null || (!isElementInViewport(scrollbar)) || !isElementInViewport(titleDiv)) {
      e.preventDefault();
      const mainPage = document.getElementById("mainPage");
      mainPage.scrollTop -= (0.4 * delta);
      return;
    }


    const timeline = document.getElementById("timelineScrollViewDiv-" + this.props.currentProject.refID);
    if (this.state.heightOfCover <= 0) {
      timeline.scrollLeft -= (0.4 * delta);
    }


    const leftScrollAmount = timeline.scrollLeft;
    const width = timeline.clientWidth;
    const scrollWidth = timeline.scrollWidth;

    const newWidth = this.calculateTimePercentage(this.state.firstPictureSeenTimestamp);


    if (Math.abs(this.scrollBarWidth - newWidth) >= 2) {
      this.setScrollBarWidth(newWidth);

      var currentAchievement = this.props.currentProject.achievements[0];
      for (var i = 0; i < this.props.currentProject.achievements.length; i++) {
        const achievement = this.props.currentProject.achievements[i];
        if (achievement.getTimestamp() <= this.state.firstPictureSeenTimestamp) {
          currentAchievement = achievement;
        }

      }
      this.setState({currentAchievement:currentAchievement})

    }
    var preferredDirection = 0;
    if (leftScrollAmount <= 0 && leftScrollAmount >= scrollWidth - width) {
      if (delta < 0) {
        preferredDirection = -1;
      } else {
        preferredDirection = 1;
      }
    }


    if (leftScrollAmount < scrollWidth - width && leftScrollAmount > 0) {
      e.preventDefault();
    } else if (this.props.collapse == null) {

    } else if (leftScrollAmount >= scrollWidth - width && preferredDirection <= 0) {
      // End of the timeline and scrolling down.
      var newHeightOfCover = this.state.heightOfCover - delta;
      var newCollapseBuffer = this.state.collapseBuffer - delta;

      if (newHeightOfCover >= collapseThreshhold) {
        if (newCollapseBuffer >= collapseBufferThreshhold) {
          this.setState({stopScrolling:true})
          this.props.collapse(()=> {
            this.animateToAchievement(this.props.currentProject.achievements[0])
            this.setState({heightOfCover:0, collapseBuffer:0, stopScrolling: false})
          });
        } else {
          this.setState({collapseBuffer:newCollapseBuffer})
        }
        e.preventDefault();
      } else if (newHeightOfCover > 0) {
        this.setState({heightOfCover:Math.max(0, Math.min(collapseThreshhold, newHeightOfCover)), collapseBuffer: 0})
        e.preventDefault();
      } else {
        this.setState({heightOfCover:0, collapseBuffer: 0})
        e.preventDefault();
      }
    } else if (leftScrollAmount <= 0 && preferredDirection >= 0) {
      // End of the timeline and scrolling down.
      var newHeightOfCover = this.state.heightOfCover + delta;
      var newCollapseBuffer = this.state.collapseBuffer + delta;

      if (newHeightOfCover >= collapseThreshhold) {
        if (newCollapseBuffer >= collapseBufferThreshhold) {
          this.setState({stopScrolling:true})
          this.props.collapse(()=> {
            this.animateToAchievement(this.props.currentProject.achievements[0])
            this.setState({heightOfCover:0, collapseBuffer:0, stopScrolling: false})
          });
        } else {
          this.setState({collapseBuffer:newCollapseBuffer})
        }
        e.preventDefault();
      } else if (newHeightOfCover > 0) {
        this.setState({heightOfCover:Math.max(0, Math.min(collapseThreshhold, newHeightOfCover)), collapseBuffer: 0})
        e.preventDefault();
      } else {
        this.setState({heightOfCover:0, collapseBuffer: 0})
        e.preventDefault();
      }
    }

  }

  onDescriptionScroll(e) {
    const delta = e.nativeEvent.wheelDelta;
    const descriptionTextDiv = document.getElementById("descriptionTextDiv-" + this.props.currentProject.refID);
    const topForDiv = descriptionTextDiv.scrollTop;
    const bottomForDiv = descriptionTextDiv.clientHeight;

    if ((topForDiv <= 0 && delta > 0) || (topForDiv + bottomForDiv >= descriptionTextDiv.scrollHeight - 2 && delta < 0)) {
      this.onPageScroll(e);
    } else if (Math.abs(e.nativeEvent.wheelDeltaX) - 1 > Math.abs(e.nativeEvent.wheelDeltaY)) {
      this.onPageScroll(e)
    }
  }

  findPreviousAchievement(oldAchievement) {
    var currentAchievement = this.props.currentProject.achievements[0];
    for (var i = 0; i < this.props.currentProject.achievements.length; i++) {
      const achievement = this.props.currentProject.achievements[i];
      if (achievement.refID === oldAchievement.refID) {
        break;
      }
      currentAchievement = achievement;
    }
    return currentAchievement;
  }

  findNextAchievement(oldAchievement) {
    var currentAchievement = this.props.currentProject.achievements[this.props.currentProject.achievements.length - 1];
    for (var i = this.props.currentProject.achievements.length - 1; i >= 0; i--) {
      const achievement = this.props.currentProject.achievements[i];
      if (achievement.refID === oldAchievement.refID) {
        break;
      }
      currentAchievement = achievement;
    }
    return currentAchievement;

  }

  animateToAchievement(achievement) {
    this.setState({currentAchievement:achievement});
    const picture = this.findPictureClosestToDate(achievement.getTimestamp());
    this.setState({scrollToColumn:picture.index})
    this.setScrollBarWidth(this.calculateTimePercentage(achievement.getTimestamp()))
  }

  findPictureClosestToDate(timestamp) {
    var closestPicture = this.props.currentProject.pictures[0]
    for (var i = 0; i < this.props.currentProject.pictures.length; i++) {
      const picture = this.props.currentProject.pictures[i];
      const distance = Math.abs(picture.getTimestamp() - timestamp);
      if (distance < Math.abs(closestPicture.getTimestamp() - timestamp)) {
        closestPicture = picture;
      }
    }
    return closestPicture;
  }

  setPictureVisibility(action) {
    if (action.pictures != null) {
      var avgDateSeen = 0;

      if (action.pictures.length > 0) {
        const picture = action.pictures[Math.floor(action.pictures.length / 3)];
        if (picture != null) {
          avgDateSeen = picture.getTimestamp();
        }

      }

      this.setState({firstPictureSeenTimestamp:avgDateSeen});
    }
  }

  render() {
    if (this.props.embedded == null) {
      return this.renderWikiStyle();
    }

    if (this.props.currentProject == null  || this.state.pictures == null || this.state.pictures.length < 1) {
      return <div>loading...</div>;
    }

    var pictures = []
    const currentProject = this.props.currentProject;

    var achievementDiv = null;
    const achievement = this.state.currentAchievement;
    if (achievement != null) {
      pictures = currentProject.pictures;

      var imageDiv = null;

      if (achievement.picture != null && achievement.picture.getSmallImageURL() !== "") {
        imageDiv = (
          <div style={{float:'right', marginRight:'20px', minWidth:'200px', minHeight:'100px'}}>
            <Image src={achievement.picture.getSmallImageURL()} style={{maxWidth:'300px', maxHeight:'300px', minWidth:'200px', minHeight:'100px'}}/>

          </div>

        )
      }
      var editDiv = (
        <span className="medium-text" style={{textAlign:'left', position:'relative', marginLeft:'40px', fontSize:14, verticalAlign:'middle', cursor:'pointer'}} onClick={()=>{this.props.selectAchievement(achievement)}}>
          edit
        </span>
      )

      if (FirebaseManager.sharedInstance().dev !== true) {
        editDiv = null;
      }
      imageDiv = null;

      achievementDiv = (
        <div key={achievement.refID} id={achievement.refID} style={{height:'100%',}}>

          <div className="large-text bold" style={{textAlign:'left', position:'relative', borderBottom: '1px solid black'}} hidden={this.props.embedded != null}>
            <span className="huge-text" style={{textAlign:'left', position:'relative'}}>
              {currentProject.title}
            </span>
          </div>
          <div className="medium-text" id={"descriptionTextDiv-" + this.props.currentProject.refID} style={{textAlign:'left', width:'100%', height:'calc(100% - 30px)', overflow:'auto', whiteSpace:'pre-wrap'}}>
            {imageDiv}
            <div>
              <span className="bold large-text" style={{textAlign:'left', position:'relative'}}>
                {achievement.title}
              </span>
              <span className="medium-text" style={{textAlign:'left', position:'relative', marginLeft:'10px', fontSize:14, verticalAlign:'middle'}}>
                {moment(achievement.getTimestamp()).format("MMM YYYY")}
              </span>
              {editDiv}
              {FirebaseManager.sharedInstance().parseLinksIntoText(achievement.description)}
            </div>
          </div>
          <br/>
        </div>
      )
    }

    if (this.scrollBarWidth == null) {
      this.scrollBarWidth = 0;
    }
    var achievementDots = null;
    if (currentProject.achievements != null) {
      achievementDots = currentProject.achievements.map(achievement => {
        const leftPosition = Math.min(Math.max(Math.round((achievement.getTimestamp() - this.state.beginTimestamp) / (this.state.endTimestamp - this.state.beginTimestamp) * 100), 0), 100)

        var style = {position:'absolute', top:0, left: "" + leftPosition + "%", width:'10px', height:'10px', borderRadius:'10px', backgroundColor:'white', border: "3px solid gray"};
        var textStyle = {position:'absolute', top:18, left: "" + leftPosition + "%", transform:'translate(-40%, 0)', whiteSpace:'nowrap', fontSize:'16px'}
        if (achievement.refID === this.state.currentAchievement.refID) {
          style.backgroundColor = "green"
          // textStyle.fontWeight = 'bold'
          style.border = "3px solid green"
        } else if (this.state.firstPictureSeenTimestamp >= achievement.getTimestamp()) {
          style.border = "3px solid green"
          textStyle.color = 'gray'
          textStyle.display = 'none'
        } else if (this.findNextAchievement(this.state.currentAchievement).refID !== achievement.refID) {
          textStyle.display = 'none'
        } else {
          textStyle.color = "rgba(0,0,0,0.3)"
          textStyle.display = 'none'
        }



        if (achievement == currentProject.achievements[0]) {
          textStyle.transform = 'translate(0, 0)'
        }
        if (achievement == currentProject.achievements[currentProject.achievements.length -1]) {
          delete textStyle.transform
          textStyle.right = '-1%'
          delete textStyle.left
        }

        return (
          <div key={achievement.refID} className="clickable" onClick={()=>{
              this.animateToAchievement(achievement)
            }}>
            <div style={style}/>
            <div style={textStyle}>
              {moment(achievement.getTimestamp()).format("MMM YYYY")}
            </div>
          </div>
        )
      })
    }

    var timelineDisplay = "block";
    if (this.state.showTimeline !== true) {
      timelineDisplay = "none"
    }

    var heightOfCover = "0vh";
    var fraction = Math.max(Math.min(this.state.heightOfCover, collapseThreshhold), 0) / collapseThreshhold;

    if (this.state.heightOfCover > 0) {
      heightOfCover = "calc(" + 95 * fraction + "px + " + 60 * fraction + "vh"
    }

    var page = (
      <div style={{width:'100%', position:'relative', height:'100%', overflow:'hidden'}} >
        <div id={"titleDiv-" + this.props.currentProject.refID} />
        <div onWheel={(e)=>{this.onPageScroll(e)}} className="fillParent" style={{position:'absolute'}} />

        <div style={{width:'100%', position:'relative', lineHeight:1.5, textAlign:'left', alignItems:'left', height:'28vh'}} onWheel={(e)=>{this.onDescriptionScroll(e)}}>
          {achievementDiv}
        </div>
        <br />

        <div id="timeline" style={{width:'100%', height:'50vh', position:'relative', paddingTop:'20px', opacity:1 - fraction * 2,}} onWheel={(e)=>{this.onPageScroll(e)}} >
          <TimelinePage setPictureVisibility={this.setPictureVisibility.bind(this)} currentAchievement={this.state.currentAchievement} rowCount={2} embedded={true} hideSidebar={true} project={this.props.currentProject} pictures={pictures} hideTopDate={true} achievements={this.props.currentProject.achievements} scrollToColumn={this.state.scrollToColumn}></TimelinePage>
        </div>


        <div style={{width:'100%', height:'50px',  position:'relative', paddingTop:'40px', opacity:1 - fraction * 2, }}>
          <div style={{height:'100%', width: "86%", marginLeft:"7%", position:'relative'}}>
            <div id={"customScrollBar-" + this.props.currentProject.refID} style={{transition: 'width 1s', height:'4px', backgroundColor:'green', width: "" + this.scrollBarWidth + "%"}}/>
            <div className="clickable" onClick={()=>{this.animateToAchievement(this.findPreviousAchievement(this.state.currentAchievement))}} style={{position:'absolute', left:'-50px', top:'-10px'}}>
              <ArrowBackIosIcon/>
            </div>
            <div className="clickable" onClick={()=>{this.animateToAchievement(this.findNextAchievement(this.state.currentAchievement))}} style={{position:'absolute', right:'-70px', top:'-10px'}}>
              <ArrowForwardIosIcon/>
            </div>
            <div style={{ height:'10px', top:'-6px', position:'absolute', left: "0px", right:'0px'}}>
              {achievementDots}
            </div>
          </div>
        </div>
        <center id="coverDiv" className="small-text" style={{width:'100%', opacity:fraction * 2, height:heightOfCover, lineHeight:heightOfCover, textAlign:'center', position:'absolute', bottom:'0px', zIndex:10}} onWheel={(e)=>{this.onPageScroll(e)}} >
           <div style={{opacity:fraction * 1.2 - 0.2, width:'200px', height:'200px', margin:'auto'}}>
             <CircularProgressbar value={fraction * 100} text={`That's all!`}
               styles={buildStyles({
                 pathTransitionDuration: 0,
                strokeLinecap: 'round',
                textSize: '12px',
                pathColor: `rgba(62, 152, 199, ${fraction})`,
                trailColor: 'white',
              })} />;
           </div>

        </center>
        <div id={"endTimeline-" + this.props.currentProject.refID} />


      </div>
    )
    if (this.props.embedded) {
      return page;
    }
    return (
      <BasicPage page={page} hideHeader={true}>
      </BasicPage>
    );
  }

  renderWikiStyle() {
    if (this.props.currentProject == null || this.state.pictures == null) {
      return <div>loading...</div>;
    }
    const pictures = this.state.pictures
    const achievements = this.props.currentProject.achievements
    const currentProject = this.props.currentProject;

    var achievementSections = null;
    if (achievements != null) {
      achievementSections = achievements.map(achievement => {
        var imageDiv = null;

        if (achievement.picture != null && achievement.picture.getImageURL() !== "") {
          imageDiv = (
            <div style={{float:'left', marginRight:'20px'}}>
              <Image src={achievement.picture.getImageURL()} style={{maxWidth:'400px', maxHeight:'600px'}}/>
              <div style={{maxWidth:'400px', height:'50px', marginTop:"-7px"}}>
                <PictureRow maxElements={6} pictures={achievement.pictures} clickPicture={(picture)=>{
                    document.getElementById("timeline").scrollIntoView();
                    this.setState({scrollToColumn:picture.index})
                  }}/>
              </div>

            </div>

          )
        }


        var editDiv = (
          <span className="medium-text" style={{textAlign:'left', position:'relative', marginLeft:'40px', fontSize:14, verticalAlign:'middle', cursor:'pointer'}} onClick={()=>{this.props.selectAchievement(achievement)}}>
            edit
          </span>
        )

        if (FirebaseManager.sharedInstance().dev !== true) {
          editDiv = null;
        }

        return (
          <div key={achievement.refID} id={achievement.refID}>

            <div className="large-text bold" style={{textAlign:'left', position:'relative', }}>
              <span style={{textAlign:'left', position:'relative'}}>
                {achievement.title}
              </span>

              <span className="medium-text" style={{textAlign:'left', position:'relative', marginLeft:'10px', fontSize:14, verticalAlign:'middle'}}>
                {moment(achievement.getTimestamp()).format("MMM YYYY")}
              </span>
              {editDiv}

            </div>
            <div className="medium-text clearfix" style={{textAlign:'left', borderTop: '1px solid black', width:'100%', overflow:'auto', whiteSpace:'pre-wrap'}}>
              {imageDiv}
              <div>{FirebaseManager.sharedInstance().parseLinksIntoText(achievement.description)}</div>


            </div>
            <br/>
          </div>
        )
      })
    }

    var page = (
      <center style={{width:'100%', position:'relative'}}>
        <div style={{width:'86%', position:'relative', lineHeight:1.5, textAlign:'left', alignItems:'left'}}>
          <div className="huge-text bold" style={{textAlign:'left', position:'relative', fontSize:40}}>
            {currentProject.title}
          </div>
          <div className="medium-text" style={{textAlign:'left', position:'relative'}}>
            {currentProject.subtitle}
          </div>
          <br />
          <br />
          <div className="medium-text" style={{textAlign:'left', position:'relative'}}>
            {currentProject.description}
          </div>

          {achievementSections}

        </div>
        <br />
        <br />
        <br />
        <br />

        <div id="timeline" style={{width:'100%', height:'95vh', position:'relative', paddingTop:'100px', paddingBottom:'100px'}} onWheel={(e)=>{this.onPageScroll(e)}} >
          <div id={"titleDiv-" + this.props.currentProject.visibleId} />
          <TimelinePage wiki={true} setPictureVisibility={this.setPictureVisibility.bind(this)} currentAchievement={this.state.currentAchievement} rowCount={3} embedded={true} hideSidebar={true} project={this.props.currentProject} pictures={pictures} hideTopDate={true} achievements={this.props.currentProject.achievements} scrollToColumn={this.state.scrollToColumn}></TimelinePage>
          <div id={"endTimeline-" + this.props.currentProject.refID} />
        </div>


      </center>
    )
    return (
      <BasicPage page={page} hideHeader={true}>
      </BasicPage>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);
