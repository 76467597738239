import React, { Component } from "react";
import { connect } from "react-redux";
import { selectPicture, selectProject, setStateKeyValue } from "../actions/index";
import BasicPage from "./BasicPage";
import TimelineSidePanel from "./TimelineSidePanel";
import TimelineScrollView from "./TimelineScrollView";
import ProjectEditor from "./ProjectEditor";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ProjectPage from "./ProjectPage";
import { Image } from "./Image";
import ShowcasedPhoto from "./ShowcasedPhoto";
import { PictureRow } from "./PictureRow";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { NavLink } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor'
import $ from "jquery";
import { MdPerson, MdComputer } from "react-icons/md";
import { FaCalendarCheck, FaCalendarTimes, FaHome } from "react-icons/fa";
import { WiSunset } from "react-icons/wi";

const FirebaseManager = require("../classes/FirebaseManager.js");
var moment = require('moment');

const mapStateToProps = (state, ownProps) => {
  return {
    currentPortfolio: state.portfolios[ownProps.match.params.id],
    change: state.updates[ownProps.match.params.id],
    selectedProject: state.selectedProject,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    selectPicture: picture => dispatch(selectPicture(picture)),
    setStateKeyValue: (key, value) => dispatch(setStateKeyValue(key, value)),
    selectProject: project => dispatch(selectProject(project))
  };
}


function findProjectForVisibleId(projects, visibleId) {
  for (var key in projects) {
    if (projects.hasOwnProperty(key)) {
      const project = projects[key];
      if (project.visibleId === visibleId) {
        return project;
      }
    }
  }
  return null;
}

function getMostVisibleElement(selector) {
    var clientRect = null;
    var clientRectTop = 0;
    var maxVisibleHeight = 0;
    var visibleHeightOfElem = 0;
    var mostVisibleElement = null;
    var skipRest = false;

    var visibleElems = $(selector).each(function(i, element) {
        if (skipRest === false) {
            clientRect = element.getBoundingClientRect();
            clientRectTop = Math.abs(clientRect.top);

            if (clientRect.top >= 0) {
                visibleHeightOfElem = window.innerHeight - clientRectTop;
            } else {
                visibleHeightOfElem = clientRect.height - clientRectTop;
            }

            if (visibleHeightOfElem >= clientRect.height) {
                mostVisibleElement = element;
                skipRest = true;
            } else {

                if (visibleHeightOfElem > maxVisibleHeight) {
                    maxVisibleHeight = visibleHeightOfElem;
                    mostVisibleElement = element;
                }
            }

        }
    });
    return mostVisibleElement;
}


class PortfolioPage extends Component {
  constructor(props) {
    super(props);


    this.state = {
      title: "",
      currentPortfolioId:null,
      scrollToColumn:0,
      currentAchievement:null,
      beginTimestamp:0,
      endTimestamp: (new Date()).getTime(),

    };
  }

  componentDidMount() {
    this.checkForScroll()


    // setTimeout(function () {
    //   var c = document.getElementById("myCanvas");
    //   console.log("try");
    //   if (c != null) {
    //     console.log("hello!");
    //     var ctx = c.getContext("2d");
    //     ctx.canvas.width  = c.innerWidth;
    //     ctx.canvas.height = c.innerHeight;
    //
    //     ctx.beginPath();
    //
    //     var i = 0;
    //     var currentY = 25;
    //     var currentX = 25;
    //
    //     while (i < 1000) {
    //       var randomDeltaY = Math.random() * 75;
    //       var randomX = Math.random() * 100;
    //
    //
    //       ctx.moveTo(currentX, currentY);
    //       ctx.lineTo(randomX, currentY + randomDeltaY);
    //       ctx.stroke();
    //
    //       currentX = randomX;
    //       currentY += randomDeltaY;
    //
    //       i++;
    //     }
    //   }
    // }.bind(this), 700);

  }

  handleScroll() {
    this.highlightVisibleDiv();
  }

  checkForScroll() {
    if (window.location.hash && this.state.movedToHash !== window.location.hash.substring(1)) {
      var hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
      const el = document.getElementById("titleDiv-" + hash);
      if (el != null) {
        const project = findProjectForVisibleId(this.props.currentPortfolio.projects, hash);

        el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

        setTimeout(function () {
            this.setState({[project.refID] : 1, movedToHash:hash})
            setTimeout(function () {
                this.setState({[project.refID] : 2})
            }.bind(this), 500);
        }.bind(this), 700);

      }
    }
  }

  componentDidUpdate() {
    this.checkForScroll()
  }

  highlightVisibleDiv() {
    var mostVisible = getMostVisibleElement("[href$='project']");
    if ((mostVisible != null && this.state.mostVisibleProjectId == null) || (mostVisible != null && mostVisible.id !== this.state.mostVisibleProjectId)) {
      this.setState({mostVisibleProjectId: mostVisible.id})
    }
  }


  doScrolling(elementY, duration) {
    var startingY = window.pageYOffset;
    var diff = elementY - startingY;
    var start;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      // Elapsed milliseconds since start of scrolling.
      var time = timestamp - start;
      // Get percent of completion in range [0, 1].
      var percent = Math.min(time / duration, 1);

      window.scrollTo(0, startingY + diff * percent);

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var newState = {};
    if (nextProps.currentPortfolio != null) {
      if (prevState.currentPortfolioId !== nextProps.currentPortfolio.refID) {
        nextProps.setStateKeyValue("pageTitle", nextProps.currentPortfolio.title)
      }
      for (var projectId in nextProps.currentPortfolio.projects) {
        if (nextProps.currentPortfolio.projects.hasOwnProperty(projectId)) {
          const project = nextProps.currentPortfolio.projects[projectId];
          project.getAchievements();
          project.getPictures();
        }
      }
      newState.currentPortfolioId = nextProps.currentPortfolio.refID;
    }
    return newState;
  }

  renderWikiStyle() {
    const currentPortfolio = this.props.currentPortfolio;
    if (currentPortfolio == null) {
      return <div>loading...</div>;
    }

    var selectedProjectDiv = null;
    if (this.props.selectedProject != null && FirebaseManager.sharedInstance().dev == true) {
      selectedProjectDiv = (
        <ProjectEditor />
      )
    }

    var addProjectButton = null;
    if (FirebaseManager.sharedInstance().dev == true) {
      addProjectButton = (
        <Fab color="primary" aria-label="add" style={{position:'fixed', bottom:'30px', left:'7%'}} onClick={()=>{this.props.selectProject({taggedPortfolios:[currentPortfolio.refID]})}}>
          <AddIcon />
        </Fab>
      )
    }



    // collapse when you scroll away


    const projects = Object.values(currentPortfolio.projects);

    var projectSections = null;
    var achievementsList = [];
    var picturesList = [];
    if (projects != null) {
      projectSections = projects.map(project => {

        var editDiv = (
          <span className="medium-text" style={{textAlign:'left', position:'relative', marginLeft:'40px', fontSize:14, verticalAlign:'middle', cursor:'pointer'}} onClick={()=>{this.props.selectProject(project)}}>
            edit
          </span>
        )

        if (FirebaseManager.sharedInstance().dev !== true) {
          editDiv = null;
        }
        var imageDiv = null;

        if (project.achievements != null && project.achievements.length > 0) {
          const achievement = project.achievements[0];
          var picture = achievement.picture;
          if (project.picture != null) {
              picture = project.picture;
          }
          if (picture != null && picture.getImageURL() !== "") {
            imageDiv = (
              <div style={{float:'right', marginLeft:'20px'}}>
                <PictureRow lengthInPixels={'350px'} showcasePhoto={picture} maxRows={4} maxElements={6} overflow={true} pictures={project.pictures} clickPicture={(picture)=>{
                    // do nothing
                    this.props.setStateKeyValue("allSelectedPictures", project.pictures);
                    this.props.selectPicture(picture);
                  }}/>

              </div>

            )
          }
        }
        var achievement = null;
        if (project.achievements != null && project.achievements.length > 0) {
          achievement = project.achievements[0];

          var otherAchievementsLinks = project.achievements.map(ach => {
            return (
              <div key={ach.refID}>
                <a onClick={()=>{
                    document.getElementById("titleDiv-" + project.visibleId).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                    this.setState({[project.refID] : 1, currentAchievement:ach})
                    setTimeout(function () {
                        this.setState({[project.refID] : 2})
                    }.bind(this), 400);
                  }}>
                  {ach.title}
                </a>
              </div>
            )
          })
          var addedStyles = {width:'auto', marginLeft: "10%", marginRight: "10%"}
          if (this.state[project.refID] >= 1 === false) {
            addedStyles.marginLeft = "30%";
            addedStyles.marginRight = "10%";
          }

          var addedStyles2 = {transition:'height 1s, minHeight 1s', width:'80%', marginLeft:'10%'}
          if (this.state[project.refID] >= 1) {
            addedStyles2.minHeight = "400px";
          }
          var opacity = ""
          if (this.state.mostVisibleProjectId != null && project.refID === this.state.mostVisibleProjectId) {
            opacity = "highlighted"
          }

          const projectDetails = project.generateProjectDetails();
          return (
            <div className={"unhighlighted " + opacity} key={project.refID} href={project.visibleId + "-project"} id={project.refID} style={{position:'relative', paddingTop:'30px', width:'100%', paddingBottom:'30px', height:'auto', transition:'height 1s, opacity 0.4s', backgroundColor:project.backgroundColor, backgroundImage:project.backgroundColorURL}}>
              <div id={"titleDiv-" + project.visibleId} />
              <div style={{textAlign:'left', position:'relative', transition:'margin-left 1s, margin-right 1s', borderBottom: '1px solid black', marginLeft:'10%', marginRight:'10%', ...addedStyles}}>

                <NavLink to={"/project/" + project.visibleId} hidden={this.state[project.refID] !== 1 && this.state[project.refID] !== 2} className="huge-text bold" style={{textAlign:'left', position:'relative'}} onClick={()=>{
                    // document.getElementById("titleDiv-" + project.visibleId).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                    //
                    // if (this.state[project.refID] !== 2) {
                    //   this.setState({[project.refID] : 1})
                    //   setTimeout(function () {
                    //       this.setState({[project.refID] : 2})
                    //   }.bind(this), 400)
                    // } else {
                    //   this.setState({[project.refID] : -1})
                    //   setTimeout(function () {
                    //       this.setState({[project.refID] : 0})
                    //   }.bind(this), 400);
                    // }
                  }}>

                  {project.title}
                </NavLink>
                <a hidden={this.state[project.refID] === 1 || this.state[project.refID] === 2} className="huge-text bold" style={{textAlign:'left', position:'relative'}} onClick={()=>{
                    document.getElementById("titleDiv-" + project.visibleId).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

                    if (this.state[project.refID] !== 2) {
                      this.setState({[project.refID] : 1})
                      setTimeout(function () {
                          this.setState({[project.refID] : 2})
                      }.bind(this), 400)
                    } else {
                      this.setState({[project.refID] : -1})
                      setTimeout(function () {
                          this.setState({[project.refID] : 0})
                      }.bind(this), 400);
                    }
                  }}>

                  {project.title}
                </a>

                <span className="large-text" hidden={true} style={{textAlign:'left', position:'relative', marginLeft:'10px', fontSize:14, verticalAlign:'middle'}}>
                  {moment(achievement.getTimestamp()).format("MMM YYYY")}
                </span>

                {editDiv}

                <a hidden={this.state[project.refID] >= 1 !== true} style={{position:'absolute', right:0, top: 10, fontSize:12}} onClick={()=>{
                    this.setState({[project.refID] : -1})
                    setTimeout(function () {
                        this.setState({[project.refID] : 0})
                    }.bind(this), 400);
                  }}>
                  <br />
                  Show less
                </a>
                <a hidden={this.state[project.refID] >= 1} style={{position:'absolute', right:0, top: 10, fontSize:12}} onClick={()=>{
                    document.getElementById("titleDiv-" + project.visibleId).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                    this.setState({[project.refID] : 1})
                    setTimeout(function () {
                        this.setState({[project.refID] : 2})
                    }.bind(this), 400);
                  }}>
                  <br />
                  Expand Story
                </a>

              </div>
              <div style={{width:'auto', marginLeft:'30%', marginRight:'10%'}} hidden={this.state[project.refID] === 2 || this.state[project.refID] === -1}>
                <Fade right when={this.state[project.refID] == null || this.state[project.refID] === 0} duration={300} >
                  <div className="medium-text clearfix" style={{textAlign:'left', width:'100%', overflow:'auto', whiteSpace:'pre-wrap', paddingTop:'10px'}}>
                    {imageDiv}
                    <div>
                      {FirebaseManager.sharedInstance().parseLinksIntoText(project.description)}
                      <br />
                      {otherAchievementsLinks}
                    </div>
                    <div id="clear" style={{clear:'left'}}></div>
                  </div>
                </Fade>

                <Fade cascade left when={(this.state[project.refID] == null || this.state[project.refID] === 0) && project.refID === this.state.mostVisibleProjectId} duration={300} delay={150} >
                  <div className="small-text" style={{position:'absolute', top:'100px', left:'10%', height:'100%', width:'20%', fontSize:16, lineHeight:'30px'}}>
                    <div className="large-text bold" style={{display:projectDetails.position != null && projectDetails.position !== "" ? 'flex' : 'none', alignItems:'center'}}>
                      <MdPerson className="large-text bold" style={{marginRight:'8px'}}/> {projectDetails.position}
                    </div>
                    <br />
                    <div style={{display:'flex', alignItems:'center'}} >
                      <FaCalendarCheck style={{marginRight:'8px'}}/> {moment(projectDetails.beginTime).format("MMM YYYY")}
                    </div>
                    <div style={{display:'flex', alignItems:'center'}}>
                      <FaCalendarTimes style={{marginRight:'8px'}}/> {moment(projectDetails.endTime).format("MMM YYYY")}
                    </div>
                    <a style={{display:projectDetails.website != null && projectDetails.website !== "" ? 'flex' : 'none', alignItems:'center'}} href={projectDetails.website} target="_blank">
                      <MdComputer style={{marginRight:'8px'}}/> {projectDetails.website}
                    </a>
                  </div>
                </Fade>
              </div>
              <div style={addedStyles2} hidden={this.state[project.refID] === 0 || this.state[project.refID] == null}>
                <Fade when={this.state[project.refID] >= 2} duration={300}>
                  <ProjectPage collapse={(callback) => {
                      this.setState({[project.refID] : -1})
                      setTimeout(function () {
                          this.setState({[project.refID] : 0})
                          callback();
                      }.bind(this), 400);
                    }} currentProject={project} pictures={project.pictures} currentAchievement={this.state.currentAchievement} embedded />
                </Fade>
              </div>
            </div>
          )
        } else if (project.achievements != null && project.achievements.length > 0) {
          achievement = project.achievements[0];
          return (
            <Fade key={project.refID} id={project.refID} style={{paddingTop:'70px'}}>

              <div className="huge-text bold" style={{textAlign:'left', position:'relative', }}>
                <NavLink to={"/project/" + project.visibleId} style={{textAlign:'left', position:'relative'}}>
                  {project.title}
                </NavLink>

                <span className="large-text" style={{textAlign:'left', position:'relative', marginLeft:'10px', fontSize:14, verticalAlign:'middle'}}>
                  {moment(achievement.getTimestamp()).format("MMM YYYY")}
                </span>

                {editDiv}

              </div>
              <div className="medium-text clearfix" style={{textAlign:'left', borderTop: '1px solid black', width:'100%', overflow:'auto', whiteSpace:'pre-wrap', paddingTop:'10px'}}>
                {imageDiv}
                <div>
                  {FirebaseManager.sharedInstance().parseLinksIntoText(achievement.description)}
                  <a onClick={()=>{this.setState({[project.refID] : true})}}>
                    <br />
                    Show More
                  </a>
                </div>


              </div>
              <br/>
            </Fade>
          )
        } else {
          return (
            <div key={project.refID} id={project.refID} style={{paddingTop:'10px'}}>

              <div className="huge-text bold" style={{textAlign:'left', position:'relative', }}>
                <NavLink to={"/project/" + project.visibleId} style={{textAlign:'left', position:'relative'}}>
                  {project.title}
                </NavLink>

                {editDiv}

              </div>
              <div className="medium-text clearfix" style={{textAlign:'left', borderTop: '1px solid black', width:'100%', overflow:'auto', whiteSpace:'pre-wrap', paddingTop:'10px'}}>
                {imageDiv}
                <div>{FirebaseManager.sharedInstance().parseLinksIntoText(project.description)}</div>
              </div>
              <br/>
            </div>
          )
        }

        return null;
      })
    }

    var project = projects[Math.round( Math.random() * projects.length )];

    var addedStylesToSide = {}

    if (project != null) {
      // addedStylesToSide.backgroundColor = project.backgroundColor;
      // addedStylesToSide.backgroundImage = project.backgroundColorURL;
    }

    var page = (
      <center style={{maxWidth:'100vw'}}>
        <div style={{
          height: 'calc(min(60vh,80vw))', zIndex: 10, position:'relative'
          }} >
          {currentPortfolio.getMainPhoto({height:"100%"}, true)}
        </div>
        <center style={{maxWidth:'90%', width:'700px', paddingTop:'50px', paddingBottom:'50px', position:'relative', backgroundColor:'white'}}>
          <div className="huge-text" style={{fontSize:'44px', fontWeight:'bold'}}>
             {currentPortfolio.title}
          </div>
          <br/>
          <br/>
          <div style={{lineHeight:'30px', fontSize:'20px'}}>
             {currentPortfolio.description}
          </div>
        </center>
        <div style={{width:'100%', position:'relative', lineHeight:1.5, textAlign:'left', alignItems:'left'}}>
          {projectSections}
        </div>
        {addProjectButton}
        {selectedProjectDiv}
      </center>
    )
    return (
      <BasicPage topMargin={"0px"} page={page} hideHeader={true} scrollHandler={this.handleScroll.bind(this)}>
      </BasicPage>
    );
  }

  render() {
    return this.renderWikiStyle();
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PortfolioPage);
