import React, { Component } from "react";
import { connect } from "react-redux";
import { addArticle } from "../actions/index";
import Button from '@material-ui/core/Button';
import { selectAchievement } from "../actions/index";
import { Image } from "./Image";

const FirebaseManager = require("../classes/FirebaseManager.js");

const mapStateToProps = state => {
  return {
    visibleAchievements: state.visibleAchievements
  };
};

function mapDispatchToProps(dispatch) {
  return {
    selectAchievement: achievement => dispatch(selectAchievement(achievement))
  };
}

class TimelineSidePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ""
    };
  }

  render() {
    var achievementsList = null;
    if (this.props.achievements != null) {
      achievementsList = this.props.achievements.map(achievement => {
        if (this.props.visibleAchievements[achievement.refID] == null) {
          return null;
        }

        var imgDiv = null;
        if (achievement.picture != null && achievement.picture.pictureData != null) {
          imgDiv = (
            <Image src={achievement.picture.pictureData.imageURL} height="160px" style={{width: "100%", height: "160px",objectFit:'cover',objectPosition:'center'}}/>
          )
        }

        var borderColor = "clear";
        if (achievement.color != null) {
          borderColor = achievement.color;
        }


        return (
          <div key={achievement.refID} onClick={()=>{
              document.getElementById(achievement.refID).scrollIntoView();
             }}>
            <div className="roundedCorners"  style={{borderColor:borderColor}}>
              {imgDiv}
              <div className="medium-text">
                {achievement.title}
              </div>
              <div className="small-text faderow">
                {FirebaseManager.sharedInstance().parseLinksIntoText(achievement.description)}
              </div>
            </div>
            <br />
            <br />
          </div>
        )
      })
    }

    return (
      <div style={{position:'relative', height:'100%', overflow:'scroll'}}>
        <div className="large-text" style={{height:'40px', width:'100%', position:'relative',}}>
          Moments
        </div>
        {achievementsList}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TimelineSidePanel);
