import * as firebase from 'firebase';
import React, { Component } from "react";
import 'firebase/firestore';
import { DatabaseObject } from "./DatabaseObject";
import { Picture } from "./Picture";
import { Achievement } from "./Achievement";
import store from "../store/index";
import { allProjects, refreshScreen } from "../actions/index";

export class Portfolio extends DatabaseObject {

  constructor(dict) {
    super();
    this.projects = {};
    this.deserialize(dict);
    this.ignoredFields["projects"] = true;

  }

  getFirestoreRef() {
    return firebase.firestore().collection(`portfolios/`);
  }

  getMainPhoto(style = {}, withTitle = false, onClick = null) {

    var parentStyle = {
      backgroundColor: '#013A6B',
      backgroundImage: '-webkit-linear-gradient(120deg, #004E95 60%, #013A6B 40%)'
    }

    var titleDiv = (
      <div className={"largeScreenOnly"} style={{position:'absolute', top: '10%', left: '10%', color: 'white', textAlign:'left'}}>
        <div className="huge-text">
          {this.title}
        </div>
        <div className="medium-text">
          {this.subtitle}
        </div>
      </div>
    );

    if (this.visibleId === "life") {
      parentStyle = {
        backgroundColor: '#606a74',
        backgroundImage: '-webkit-linear-gradient(120deg, #3e4952 60%, #606a74 40%)'
      }
      style.width = "100%";
      style.objectFit = "cover"
    } else if (this.visibleId === "career") {
      parentStyle = {
        backgroundColor: '#606a74',
        backgroundImage: '-webkit-linear-gradient(120deg, #3e4952 60%, #606a74 40%)'
      }
      style.width = "100%";
      style.objectFit = "cover"
    }

    if (onClick != null) {
      parentStyle.cursor = "pointer";
    }



    if (withTitle !== true) {
      titleDiv = null;
    }
    return (
      <div key={this.refID} style={{
        ...parentStyle,
        height: '100%', width: '100%',
      }} className="centerAndFillImage" onClick={()=>{
        if (onClick != null) {
          onClick();
        }
      }}>

        {this.getMainPhotoGraphic(style)}
        {titleDiv}
      </div>
    )
  }

  getMainPhotoGraphic(style) {
    return (
      <div data-src="" style={{
        position:'relative', width:'100%', height:'100%', display:'flex', justifyContent:'center'
        }} >
        <img style={{width:'auto', height:'100%', ...style}} src={this.getImageSrc()}/>
      </div>
    )
  }

  getSampleOfPhotos() {
    var allPhotos = [];
    if (this.projects != null) {
      const projects = this.projects
      for (var i = 0; i < projects.length; i++) {
        const project = projects[i]
        allPhotos.push(... project.pictures);
      }
    }

    return allPhotos;
  }

  getImageSrc() {
    return require("../../images/" + this.imageName);
  }

  saveToDatabase(refIdCallback) {
    if (this.visibleId == null && this.refID != null) {
      this.visibleId = this.refID;
    }

    super.saveToDatabase(refIdCallback);
  }

  deserialize(dict) {
    for (var key in dict) {
      if (dict.hasOwnProperty(key)) {
        this[key] = dict[key];
      }
    }
  }
}
