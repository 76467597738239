import React, { Component } from "react";
import { Image } from "./Image";
import { connect } from "react-redux";
import { selectPicture, selectAchievement, setStateKeyValue } from "../actions/index";
import PhotoEditor from "./PhotoEditor";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import $ from 'jquery';
import { PictureRow } from "./PictureRow";




var moment = require('moment');
const FirebaseManager = require("../classes/FirebaseManager.js");

const mapStateToProps = state => {
  return {
    selectedPicture: state.selectedPicture,
    allSelectedPictures: state.allSelectedPictures,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    selectPicture: picture => dispatch(selectPicture(picture)),
    setStateKeyValue: (key, value) => dispatch(setStateKeyValue(key, value)),
  };
}

function isStringNull(string) {
  return (string == null || string === "")
}


class ShowcasedPhoto extends Component {
  safelySelectPhotoFromIndex(index, allPhotos) {
    if (index < 0) {
      if (allPhotos.length > 0) {
        return this.safelySelectPhotoFromIndex(allPhotos.length + index, allPhotos);
      }
      return allPhotos[allPhotos.length + index];
    }

    return allPhotos[index % allPhotos.length];
  }

  findNextPhoto(self) {
    if (self.props.selectedPicture != null && self.props.allSelectedPictures != null) {
      for (var i = 0; i < self.props.allSelectedPictures.length; i++) {
        const iteratedPicture = self.props.allSelectedPictures[i];
        if (self.props.selectedPicture.refID === iteratedPicture.refID) {
          const photo = self.safelySelectPhotoFromIndex(i - 1, self.props.allSelectedPictures);
          self.props.selectPicture(photo)
          break;
        }
      }
    }
  }

  findPrevPhoto(self) {
    if (self.props.selectedPicture != null && self.props.allSelectedPictures != null) {
      for (var i = 0; i < self.props.allSelectedPictures.length; i++) {
        const iteratedPicture = self.props.allSelectedPictures[i];
        if (self.props.selectedPicture.refID === iteratedPicture.refID) {
          const photo = self.safelySelectPhotoFromIndex(i + 1, self.props.allSelectedPictures);
          self.props.selectPicture(photo)
          break;
        }
      }
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      title: ""
    };

    const self = this;

    $(document).keydown(function(e) {
      if (self.props.selectedPicture != null) {
        switch(e.which) {
            case 37: // left
              self.findNextPhoto(self);
              break;
            case 39: // right
              self.findPrevPhoto(self);
              break;

            default: return; // exit this handler for other keys
        }
        e.preventDefault(); // prevent the default action (scroll / move caret)
      }


    });
  }



  render() {
    if (this.props.selectedPicture == null) {
      return null;
    }


    var overlayBelow = null;
    if ((this.props.selectedPicture.description != null && this.props.selectedPicture.description !== "") || (this.props.selectedPicture.title != null  && this.props.selectedPicture.title !== "") || true) {
      overlayBelow = (
        <div className="imgOverlay" style={{background:'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.49763655462184875) 29%, rgba(0,0,0,0.7021183473389356) 100%)', overflow:'hidden'}}>
          <div className="medium-text">
            {moment(this.props.selectedPicture.getTimestamp()).format("MMM YYYY")}
          </div>
          <div className={"large-text " + (isStringNull(this.props.selectedPicture.title) ? 'hidden' : 'show')}>
            {this.props.selectedPicture.title}
          </div>
          <div className={"small-text " + (isStringNull(this.props.selectedPicture.description) ? 'hidden' : 'show')} style={{marginTop:'4px', fontSize: 16}}>
            {this.props.selectedPicture.description}
          </div>
        </div>
      )
    }

    var firstBatchOfPhotos = [];
    var secondBatchOfPhotos = [];
    var foundPhoto = false;
    var currentIndex = 0;



    var selectedPhotoIndexLabel = null;
    if (this.props.allSelectedPictures != null) {

      for (var i = 0; i < this.props.allSelectedPictures.length; i++) {
        const picture = this.props.allSelectedPictures[i];
        if (picture.refID === this.props.selectedPicture.refID) {
          foundPhoto = true
          currentIndex = i + 1;
        } else if (foundPhoto === false) {
          firstBatchOfPhotos.push(picture);
        } else {
          secondBatchOfPhotos.push(picture);
        }
      }
      selectedPhotoIndexLabel = (
        <div style={{position:'fixed', width:'5vw', bottom:20, left:'47.5%', zIndex:55, height:'50px', lineHeight:'50px', color:'white'}}>
          {currentIndex} / {this.props.allSelectedPictures.length}
        </div>
      )
    }

    var showCasedStyle = {
      margin: 'auto',
      maxHeight: '80vh',
      maxWidth: '80vw',
      minWidth: '300px',
      minHeight:'300px',
    }

    var exitBackgroundDiv = (
      <div className="fillParent" style={{position:'absolute'}} onClick={()=>{this.props.selectPicture(null)}}>

      </div>
    )

    var displaySelectedPicture = (
      <div className="fullscreen darkOverlay" style={{textAlign:'center', display:'flex', zIndex:50}}>
        {exitBackgroundDiv}

        <div className="fullscreen" style={{height:'90%', display:'flex', color:'white', fontSize:60}}>
          {exitBackgroundDiv}
          <div className="clickable" onClick={()=>{this.findPrevPhoto(this)}} style={{position:'absolute', left:'80px', top:'50%', zIndex:55}}>
            <ArrowBackIosIcon fontSize="large"/>
          </div>
          <div className="clickable" onClick={()=>{this.findNextPhoto(this)}} style={{position:'absolute', right:'80px', top:'50%', zIndex:55}}>
            <ArrowForwardIosIcon fontSize="large"/>
          </div>
          <div style={{margin:'auto', position:'relative', textAlign:'left', display:'flex', flexDirection:'column'}}>
            <Image highQuality={true} fade={false} fancyLoad={true} playable={true} autoplay muted picture={this.props.selectedPicture} className="imgWithOverlay" style={showCasedStyle}/>
            <div className="imgWithOverlay fillParent" style={{position:'absolute'}}/>
            {overlayBelow}

          </div>
        </div>

        <div style={{position:'fixed', width:'40vw', bottom:20, left:'5%', zIndex:55, height:'50px'}}>
          {exitBackgroundDiv}
          <PictureRow reverse={true} lengthInPixels={'100%'} maxRows={1} maxElements={10} overflow={false} pictures={firstBatchOfPhotos} clickPicture={(picture)=>{
              // do nothing
              this.props.selectPicture(picture);
            }}/>
        </div>
        <div style={{position:'fixed', width:'40vw', bottom:20, right:'5%', zIndex:55, height:'50px'}}>
          {exitBackgroundDiv}
          <PictureRow lengthInPixels={'100%'} maxRows={1} maxElements={10} overflow={false} pictures={secondBatchOfPhotos} clickPicture={(picture)=>{
              // do nothing
              this.props.selectPicture(picture);
            }}/>
        </div>

        {selectedPhotoIndexLabel}

      </div>
    )

    if (FirebaseManager.sharedInstance().dev) {
      displaySelectedPicture = (
        <PhotoEditor/>
      )
    }

    return displaySelectedPicture;
  }
}


export default connect(
  mapStateToProps, mapDispatchToProps
)(ShowcasedPhoto);;
