import React, { Component } from "react";
import { connect } from "react-redux";
import { addArticle } from "../actions/index";
import JCIcon from "../../images/JCIcon.png";
import { NavLink } from 'react-router-dom';
const mapStateToProps = state => {
  return {
    pageTitle: state.pageTitle,
  };
};

class PageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ""
    };
  }

  render() {

    const pageHeaderLinks = [];

    pageHeaderLinks.push(
      {
        title: "Home",
        link: "/home"
      }
    );
    pageHeaderLinks.push(
      {
        title: "Timeline",
        link: "/timeline"
      }
    );
    pageHeaderLinks.push(
      {
        title: "About",
        link: "/about"
      }
    );

    const linksDiv = pageHeaderLinks.map(link => {
      return (
        <span key={link.link}>
          <NavLink to={link.link}>{link.title} / </NavLink>
        </span>
      )
    })

    return (
      <center style={{height:'60px', width:'100%', position:'fixed', backgroundColor:'white', top:'0px', left:'0px', right:'0px', zIndex:5, borderBottom:'1px dotted rgba(0,0,0,0.3', overflow:'hidden'}}>
        <div style={{height:'60px', width:'90%', position:'relative',}}>
          <NavLink to={"/"} className="huge-text" style={{position:'absolute', top:'0px', height:'80px', zIndex: 15, right:'0px', lineHeight:'60px'}}>
            <img src={JCIcon} height={30}/>
          </NavLink>
          <div className="large-text bold" style={{position:'absolute', top:'0px', left:'0px', height:'60px', fontSize:24, lineHeight:'60px'}}>
            {this.props.pageTitle}
          </div>
        </div>
      </center>

    );
  }
}
export default connect(
  mapStateToProps
)(PageHeader);
