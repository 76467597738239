import React, { Component } from "react";
import './App.css';
import HomePage from "./HomePage";
import TimelinePage from "./TimelinePage";
import ProjectPage from "./ProjectPage";
import PortfolioPage from "./PortfolioPage";
import { Route, Redirect, Switch } from 'react-router-dom';

import smoothscroll from 'smoothscroll-polyfill';
import BlogPostPage from "./BlogPostPage";

const FirebaseManager = require("../classes/FirebaseManager.js");
// kick off the polyfill!
smoothscroll.polyfill();

class App extends Component {

  constructor() {
    super();
    if (FirebaseManager.shared == null) {
      FirebaseManager.createSharedInstance();
    }
  }


  render() {
    return (
      <div className="App">
        <Switch>
          <Route path='/home' component={HomePage} />
          <Route path='/timeline' component={TimelinePage} />
          <Route path='/project/:id' component={ProjectPage} />
          <Route path='/blog/:id' component={BlogPostPage} />
          <Route path='/portfolio/:id' component={PortfolioPage} />
          <Route component={HomePage} />
        </Switch>
      </div>
    );
  }

}

export default App;
