import * as firebase from 'firebase';
import 'firebase/firestore';
import { DatabaseObject } from "./DatabaseObject";
import { Picture } from "./Picture";

export class Achievement extends DatabaseObject {

  constructor(dict) {
    super();
    this.deserialize(dict);
    this.ignoredFields["baseRefURL"] = true;
    this.ignoredFields["project"] = true;
    this.ignoredFields["color"] = true
    this.ignoredFields["pictures"] = true;

    this.color = "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);});
    this.color = "rgba(0,0,0,0)"
  }

  saveToDatabase() {
    if (this.project != null && this.baseRefURL == null) {
      this.baseRefURL = this.project.getBaseURLForAchievements();
    }
    if (this.timestamp == null) {
      this.timestamp = (new Date()).getTime()
    }
    super.saveToDatabase();
  }

  getTimestamp() {
    if (this.timestamp == null) {
      return (new Date()).getTime()
    }
    return this.timestamp
  }

  getFirestoreRef() {
    if (this.baseRefURL != null) {
      return firebase.firestore().collection(this.baseRefURL);
    }
    return firebase.firestore().collection(`achievements/`);
  }

  deserialize(dict) {
    for (var key in dict) {
      if (dict.hasOwnProperty(key)) {
        if (key == "picture") {
          const picture = new Picture(dict[key]);
          this[key] = picture;
        } else {
          this[key] = dict[key];
        }
      }
    }
  }
}
