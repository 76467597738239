import { ADD_ARTICLE } from "../constants/action-types";

const initialState = {
  articles: [],
  remoteArticles: [],
  pictures: [],
  achievements: [],
  achievementColors: {},
  visibleAchievements: {},
  projects: {},
  portfolios: {},
  updates: {}
};

function compareKeys(a, b) {
  var aKeys = Object.keys(a).sort();
  var bKeys = Object.keys(b).sort();
  return JSON.stringify(aKeys) === JSON.stringify(bKeys);
}

function insertProjectsToPortfolios(projects, portfolios) {
  for (var key in portfolios) {
    if (portfolios.hasOwnProperty(key)) {
      const portfolio = portfolios[key];
      for (var i = 0; i < portfolio.projectIds.length; i++) {
        const projectId = portfolio.projectIds[i];
        const project = findProjectForRefId(projects, projectId);
        if (project != null) {
          portfolio.projects[project.refID] = project;
        }
      }
    }
  }
}

function findProjectForRefId(projects, refID) {
  for (var key in projects) {
    if (projects.hasOwnProperty(key)) {
      const project = projects[key];
      if (project.refID === refID) {
        return project;
      }
    }
  }
  return null;
}

function findProjectForVisibleId(projects, visibleId) {
  for (var key in projects) {
    if (projects.hasOwnProperty(key)) {
      const project = projects[key];
      if (project.visibleId === visibleId) {
        return project;
      }
    }
  }
  return null;
}

function findPortfolioVisibleIdsForProject(project, portfolios) {
  var visibleIdsObject = {};
  for (var key in portfolios) {
    if (portfolios.hasOwnProperty(key)) {
      const portfolio = portfolios[key];
      for (var i = 0; i < portfolio.projectIds.length; i++) {
        const projectId = portfolio.projectIds[i];
        if (projectId === projectId) {
          visibleIdsObject[portfolio.visibleId] = true;
        }
      }
    }
  }
  return visibleIdsObject;
}

function rootReducer(state = initialState, action) {
	if (action.type === ADD_ARTICLE) {
		return Object.assign({}, state, {
	     	articles: state.articles.concat(action.payload)
		});
	}
  if (action.type === "PICTURES") {
    return Object.assign({}, state, {
      pictures: action.payload
    });
  }
  if (action.type === "UPDATE") {
    var updateObject = {}
    updateObject[action.payload.refreshId] = state.updates[action.payload.refreshId] !== true;
    const project = findProjectForVisibleId(state.projects, action.payload.refreshId);
    if (project != null) {
      const portfolioUpdates = findPortfolioVisibleIdsForProject(project, state.portfolios);
      for (var key in portfolioUpdates) {
        if (portfolioUpdates.hasOwnProperty(key)) {
          updateObject[key] = state.updates[key] !== true;
        }
      }

      updateObject["home"] = updateObject["home"] !== true
    }
    return Object.assign({}, state, { updates : updateObject});
  }
  if (action.type === "ACHIEVEMENTS") {
    return Object.assign({}, state, {
      achievements: action.payload
    });
  }
  if (action.type === "PROJECTS") {
    var updatedProjects = {}
    for (var key in action.payload) {
      if (action.payload.hasOwnProperty(key)) {
        const project = action.payload[key];
        updatedProjects[project.visibleId] = project;
      }
    }

    var updatedPortfolios = state.portfolios;
    insertProjectsToPortfolios(updatedProjects, updatedPortfolios);

    return Object.assign({}, state, {
      projects: { ...state.projects, ...updatedProjects },
      portfolios: { ...state.portfolios, ...updatedPortfolios }
    });
  }
  if (action.type === "PORTFOLIOS") {
    var updatedPortfolios = {};
    for (var key in action.payload) {
      if (action.payload.hasOwnProperty(key)) {
        const portfolio = action.payload[key];
        updatedPortfolios[portfolio.visibleId] = portfolio;
      }
    }
    insertProjectsToPortfolios(state.projects, updatedPortfolios);
    console.log(updatedPortfolios);
    return Object.assign({}, state, {
      portfolios: updatedPortfolios
    });
  }
  if (action.type === "ACHIEVEMENT_COLORS") {
    return Object.assign({}, state, {
      achievementColors: action.payload
    });
  }
  if (action.type === "SELECTED_PICTURE") {
    if (state.selectedPicture != null && action.payload == null) {
      const widget = document.getElementById('bmc-wbtn');
      if (widget != null) {
        widget.style.opacity = 1;
      }
    } else if (state.selectedPicture == null && action.payload != null) {
      const widget = document.getElementById('bmc-wbtn');
      if (widget != null) {
        widget.style.opacity = 0;
      }
    }

    return Object.assign({}, state, {
      selectedPicture: action.payload
    });
  }
  if (action.type === "PICTURE_VISIBILITY") {
    if (action.payload != null) {
      var currentVisibleAchievements = {};
      var avgDateSeen = 0;
      for (var picture of action.payload.pictures) {
        for (var achievementKey in picture.taggedAchievements) {
          if (currentVisibleAchievements[achievementKey] == null) {
            currentVisibleAchievements[achievementKey] = {};
          }
          currentVisibleAchievements[achievementKey] = true;
        }
      }

      if (action.payload.pictures.length > 0) {
        avgDateSeen = action.payload.pictures[Math.floor(action.payload.pictures.length / 3)].getTimestamp();
      }


      if (compareKeys(currentVisibleAchievements, state.visibleAchievements) === true) {
        return Object.assign({}, state, {
          firstPictureSeenTimestamp: avgDateSeen,
        });
      }

      return Object.assign({}, state, {
        visibleAchievements: currentVisibleAchievements,
        firstPictureSeenTimestamp: avgDateSeen,
      });
    }
  }
  if (action.type === "SELECTED_ACHIEVEMENT") {
    return Object.assign({}, state, {
      selectedAchievement: action.payload
    });
  }
  if (action.type === "SELECTED_PROJECT") {
    return Object.assign({}, state, {
      selectedProject: action.payload
    });
  }
  if (action.type === "SET_KEY_VALUE") {
    var newState = {};
    newState[action.payload.key] = action.payload.value;
    return Object.assign({}, state, newState);
  }
  if (action.type === "DATA_LOADED") {
    return Object.assign({}, state, {
      remoteArticles: state.remoteArticles.concat(action.payload)
    });
  }
	return state;
};

export default rootReducer;
