import { ADD_ARTICLE } from "../constants/action-types";
import { Picture } from "../classes/Picture";
import { Achievement } from "../classes/Achievement";
import { Project } from "../classes/Project";
const forbiddenWords = ["spam", "money"];

export function forbiddenWordsMiddleware({ dispatch }) {
  return function(next) {
    return function(action) {
      // do your stuff
      if (action.type === "SELECTED_PICTURE") {
        if (action.payload != null && !(action.payload instanceof Picture)) {
          action.payload = new Picture(action.payload);
        }
      }
      if (action.type === "SELECTED_ACHIEVEMENT") {
        if (action.payload != null && !(action.payload instanceof Achievement)) {
          action.payload = new Achievement(action.payload);
        }
      }
      if (action.type === "SELECTED_PROJECT") {
        if (action.payload != null && !(action.payload instanceof Project)) {
          action.payload = new Project(action.payload);
        }
      }
      if (action.type === ADD_ARTICLE) {
        const foundWord = forbiddenWords.filter(word =>
          action.payload.title.includes(word)
        );
        if (foundWord.length) {
          return dispatch({ type: "FOUND_BAD_WORD" });
        }
      }
      return next(action);
    };
  };
}
