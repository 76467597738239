import React, { Component } from "react";
import { connect } from "react-redux";
import { selectPicture, selectAchievement } from "../actions/index";
import BasicPage from "./BasicPage";
import TimelineSidePanel from "./TimelineSidePanel";
import TimelineScrollView from "./TimelineScrollView";
import AchievementEditor from "./AchievementEditor";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const FirebaseManager = require("../classes/FirebaseManager.js");

const mapStateToProps = state => {
  return {
    selectedAchievement: state.selectedAchievement,
    visibleAchievements: state.visibleAchievements
  };
};

function mapDispatchToProps(dispatch) {
  return {
    selectPicture: picture => dispatch(selectPicture(picture)),
    selectAchievement: achievement => dispatch(selectAchievement(achievement))
  };
}

class TimelinePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ""
    };
  }

  render() {
    var page = null;

    var selectedAchievementDiv = null;
    if (this.props.selectedAchievement != null && FirebaseManager.sharedInstance().dev == true) {
      selectedAchievementDiv = (
        <AchievementEditor />
      )
    }

    var addSectionButton = null;
    var addPhotoButton = null;
    if (FirebaseManager.sharedInstance().dev == true) {
      if (this.props.wiki != null) {
        addSectionButton = (
          <Fab color="primary" aria-label="add" style={{position:'fixed', bottom:'30px', left:'7%'}} onClick={()=>{this.props.selectAchievement({project:this.props.project})}}>
            <AddIcon />
          </Fab>
        )
        addPhotoButton = (
          <Fab color="secondary" aria-label="add" style={{position:'fixed', bottom:'30px', right:'7%'}} onClick={()=>{this.props.selectPicture({project:this.props.project})}}>
            <AddIcon />
          </Fab>
        )
      } else {
        addSectionButton = (
          <Fab color="primary" aria-label="add" style={{position:'absolute', bottom:'30px', left:'7%'}} onClick={()=>{this.props.selectAchievement({project:this.props.project})}}>
            <AddIcon />
          </Fab>
        )
        addPhotoButton = (
          <Fab color="secondary" aria-label="add" style={{position:'absolute', bottom:'30px', right:'7%'}} onClick={()=>{this.props.selectPicture({project:this.props.project})}}>
            <AddIcon />
          </Fab>
        )
      }

    }


    var hideSidebar = true;

    if (this.props.visibleAchievements != null && this.props.hideSidebar !== true) {
      for (var key in this.props.visibleAchievements) {
        for (var index in this.props.achievements) {
          if (this.props.achievements[index].refID === key) {
            hideSidebar = false
          }
        }
      }
    }

    var sideBarStyle = {};
    var timelineStyle = {};
    if (hideSidebar) {
      sideBarStyle.width = "0%";
    } else {
      timelineStyle.marginLeft = "21%"
    }

    page = (
      <div style={{position:'relative', height:'100%', width:'100%'}}>
        <div style={{ width:'20%', position:'absolute', height:'100%', textAlign:'left', float:'left', transition: 'all .3s linear', ...sideBarStyle}}>
          <TimelineSidePanel achievements={this.props.achievements}/>
        </div>
        <div style={{position:'absolute', height:'100%', width:'100%', textAlign:'left', transition: 'all .3s linear', ...timelineStyle}}>
          <TimelineScrollView wiki={this.props.wiki} currentProject={this.props.project} setPictureVisibility={this.props.setPictureVisibility} currentAchievement={this.props.currentAchievement} rowCount={this.props.rowCount} pictures={this.props.pictures} hideTopDate={this.props.hideTopDate} achievements={this.props.achievements} hideSidebar={hideSidebar} scrollToColumn={this.props.scrollToColumn}/>
        </div>

        {addSectionButton}
        {addPhotoButton}
        {selectedAchievementDiv}
      </div>
    )

    if (this.props.embedded) {
      return page;
    }
    return (
      <BasicPage page={page}>
      </BasicPage>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TimelinePage);
