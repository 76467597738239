import * as firebase from 'firebase';
import 'firebase/firestore';
import { DatabaseObject } from "./DatabaseObject";

export class Picture extends DatabaseObject {

  constructor(dict) {
    super(dict);
    this.ignoredFields["tempURL"] = true;
    this.ignoredFields["index"] = true;
    this.ignoredFields["baseRefURL"] = true;
    this.ignoredFields["project"] = true;
    this.ignoredFields["objectPosition"] = true
  }

  saveToDatabase() {
    if (this.project != null && this.baseRefURL == null) {
      this.baseRefURL = this.project.getBaseURLForPictures();
    }

    if (this.getTimestamp() > 0) {
      this.timestamp = this.getTimestamp();
    } else {
      this.timestamp = (new Date()).getTime();
    }

    super.saveToDatabase();
  }

  getFirestoreRef() {
    if (this.baseRefURL != null) {
      return firebase.firestore().collection(this.baseRefURL);
    }
    return firebase.firestore().collection(`pictures/`);
  }

  getMediaType() {
    if (this.pictureData != null && this.pictureData.type != null) {
      return this.pictureData.type;
    }
    return "image";
  }

  deleteFromDatabase() {
    super.deleteFromDatabase();
    this.deletePictureDataFromStorage();
  }

  getObjectPosition() {
    var objectPosition = 'center';
    if (this.horizontalAlign != null) {
      objectPosition = this.horizontalAlign
    } else {
      objectPosition = "50%"
    }

    if (this.verticalAlign != null) {
      objectPosition = objectPosition + " " + this.verticalAlign
    } else {
      objectPosition = objectPosition + " " + "50%"
    }

    return objectPosition;
  }

  getDimensions() {
    if (this.pictureData != null && this.pictureData.width != null && this.pictureData.height != null) {
      return {width: this.pictureData.width, height: this.pictureData.height};
    }

    return null;
  }

  getTimestamp() {
    if (this.timestamp != null) {
      return this.timestamp;
    }
    if (this.pictureData != null && this.pictureData.timestamp) {
      return this.pictureData.timestamp;
    }

    return 0;
  }

  getImageURL() {
    if (this.pictureData != null && this.pictureData.imageURL != null) {
      return this.pictureData.imageURL;
    }
    if (this.tempURL != null) {
      return this.tempURL;
    }
    return "";
  }

  getSmallImageURL() {
    if (this.pictureData != null && this.pictureData.smallImageURL != null) {
      return this.pictureData.smallImageURL;
    }
    return this.getImageURL();
  }

  getTinyImageURL() {
    if (this.pictureData != null && this.pictureData.tinyImageURL != null) {
      return this.pictureData.tinyImageURL;
    }
    return this.getSmallImageURL();
  }

  deletePictureDataFromStorage() {

    if (this.pictureData != null && this.pictureData.imageStorageURL != null) {
      this.deletePictureURL(this.pictureData.imageStorageURL);
    }
    if (this.pictureData != null && this.pictureData.smallImageStorageURL != null) {
      this.deletePictureURL(this.pictureData.smallImageStorageURL);
    }
  }

  deletePictureURL(pictureURL) {
    firebase.storage().ref().child(pictureURL).delete().then(function() {
      console.log('Deleted an image!');
    }).catch(function(error) {
      console.log(error);
    });
  }

}
