import React, { Component } from "react";
import { connect } from "react-redux";
import {addArticle, selectPicture, selectProject, setStateKeyValue} from "../actions/index";
import BasicPage from "./BasicPage";
import 'react-awesome-slider/dist/styles.css';
import { Editor } from 'react-draft-wysiwyg';
import { composeDecorators } from 'draft-js-plugins-editor';
import { EditorState, convertToRaw, convertFromRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ProjectEditor from "./ProjectEditor";
import {Image} from "./Image";
import MyDraftEditor from "./MyDraftEditor";
import createImagePlugin from 'draft-js-image-plugin';
import createBlockDndPlugin from 'draft-js-drag-n-drop-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';

const FirebaseManager = require("../classes/FirebaseManager.js");
const moment = require('moment');

const blockDndPlugin = createBlockDndPlugin();
const focusPlugin = createFocusPlugin();

const decorator = composeDecorators(
    focusPlugin.decorator,
    blockDndPlugin.decorator
);

const imagePlugin = createImagePlugin({ decorator });

const plugins = [
  blockDndPlugin,
  focusPlugin,
  imagePlugin
];

const mapStateToProps = (state, ownProps) => {

  var projectVisibleId = null;
  if (ownProps != null && ownProps.match != null && ownProps.match.params != null) {
    projectVisibleId = ownProps.match.params.id;
  }
  return {
    currentBlogProject: state.projects[projectVisibleId],
    change: state.updates[projectVisibleId],
    selectedAchievement: state.selectedAchievement,
    selectedPicture: state.selectedPicture,
    selectedProject: state.selectedProject,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addArticle: article => dispatch(addArticle(article)),
    selectProject: project => dispatch(selectProject(project)),
    selectPicture: project => dispatch(selectPicture(project)),
    setStateKeyValue: (key, value) => dispatch(setStateKeyValue(key, value)),
  };
}

class EditorImage extends Component {
  render() {
    if (this.props.blockProps != null) {

      return (
          <center style={{paddingBottom:'1px'}}>
            <Image {...this.props.blockProps} />
            <br />
            <p style={{textAlign:'center', fontSize: 16, marginTop:'-10px', fontStyle:'italic'}}>
              {this.props.blockProps.picture.description}
            </p>
          </center>
      );
    }
    return <Image  />;
  }
}



class BlogPostPage extends Component {
  constructor(props) {
    super(props);

    const fieldsToSave = {
      description: "Hey Jack! I'm stoked you are writing a new blog post :)",

    };

    this.pictureImageURLsToPicturesIncluded = {}

    this.state = {
      fieldsToSave: fieldsToSave
    };
  }

  uploadImageCallBack(file) {
    const project = this.props.currentBlogProject;
    var self = this;
    return new Promise(
        (resolve, reject) => {
          console.log(file)
          self.firebaseUpload(file, project)
              .then(link => {
                resolve({ data: { link } });
              })
              .catch(error => {
                reject(error);
              })
        }
    );
  }

  firebaseUpload (file, project) {
    return new Promise(
        (resolve, reject) => {
          FirebaseManager.sharedInstance().uploadImage(file, (picture => {
            if (picture.getImageURL() !== "" && picture.getImageURL != null) {
              picture.project = project;
              picture.saveToDatabase();
              resolve(picture.getImageURL())
            }
          }))
        }
    );
  }

  onEditorStateChange = (editorState) => {
    const randomId = FirebaseManager.sharedInstance().randomId();


    this.setState({
      editorState,
      autosavingId: randomId
    });

    setTimeout(function () {
      if (randomId === this.state.autosavingId) {
        this.autoSaveTemporaryContent(this.props.currentBlogProject);
      }
    }.bind(this), 5000);
  };

  autoSaveTemporaryContent(objectToSave) {
    console.log("autosaving.")
    objectToSave.rawContentJSONTemporary = convertToRaw(this.state.editorState.getCurrentContent());
    FirebaseManager.sharedInstance().saveIfPossible(objectToSave);
    this.setState({
      autosavingId: null
    });

  }

  save(objectToSave) {
    for (const field in this.state.fieldsToSave) {
      if (this.state.fieldsToSave.hasOwnProperty(field)) {
        objectToSave[field] = this.state.fieldsToSave[field];
      }
    }

    objectToSave.rawContentJSON = convertToRaw(this.state.editorState.getCurrentContent());
    FirebaseManager.sharedInstance().saveIfPossible(objectToSave);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const currentBlogProject = nextProps.currentBlogProject;
    if (currentBlogProject != null) {

      currentBlogProject.getPictures();

      var newState =  {
        editableId: currentBlogProject.refID,
        change: nextProps.change
      }

      for (var field in prevState.fieldsToSave) {
        if (prevState.fieldsToSave.hasOwnProperty(field) && currentBlogProject.hasOwnProperty(field)) {
          newState[field] = currentBlogProject[field];
        }
      }

      if (currentBlogProject.refID !== prevState.editableId) {
        nextProps.setStateKeyValue("pageTitle", nextProps.currentBlogProject.title)
        const content = currentBlogProject.rawContentJSON;
        const contentTemp = currentBlogProject.rawContentJSONTemporary;
        if (FirebaseManager.sharedInstance().dev === true && contentTemp != null && contentTemp.blocks != null) {
          newState.editorState = EditorState.createWithContent(convertFromRaw(contentTemp));
        } else if (content != null && content.blocks != null) {
          newState.editorState = EditorState.createWithContent(convertFromRaw(content));
        }

      } else if (nextProps.change !== prevState.change) {
        newState.editorState = EditorState.createWithContent(prevState.editorState.getCurrentContent());
      }

      return newState;
    }

    return null;
  }

  startNewPost() {
    if (this.props != null && this.props.match != null && this.props.match.params != null) {
      var projectVisibleId = this.props.match.params.id;
      this.props.selectProject({visibleId:projectVisibleId, type:"blog"});
    }
  }

  editPost() {
    this.props.selectProject(this.props.currentBlogProject);
  }

  myBlockRenderer(contentBlock) {

    var showCasedStyle = {
      margin: 'auto',
      width: '100%',
    }

    const type = contentBlock.getType();


    if (type === 'atomic') {
      const project = this.props.currentBlogProject;
      const pictureImageURLsToPicture = {}
      if (project.pictures != null) {
        for (var picture in project.pictures) {
          const photo = project.pictures[picture];
          pictureImageURLsToPicture[photo.getImageURL()] = photo;
        }
      }



      const data = this.state.editorState.getCurrentContent().getEntity(contentBlock.getEntityAt(0)).getData();

      const photo = pictureImageURLsToPicture[data.src];

      this.pictureImageURLsToPicturesIncluded[data.src] = photo;
      if (photo != null) {
        return {
          component: EditorImage,
          editable: false,
          props: {
            picture: photo,
            highQuality:true,
            style: showCasedStyle,
            fancyLoad:true,
            onClick:() => {
              if (FirebaseManager.sharedInstance().dev !== true || true) {
                this.props.selectPicture(photo)
                this.props.setStateKeyValue("allSelectedPictures", Object.values(this.pictureImageURLsToPicturesIncluded));
              }

            }
          },
        };
      }

    }
  }

  render() {

    var page = null;
    const currentBlogProject = this.props.currentBlogProject;

    if (currentBlogProject == null) {
      if (FirebaseManager.sharedInstance().dev === true) {
        page = (
            <div style={{position:'relative', marginTop:'20px'}}>
              <a onClick={()=>{this.startNewPost()}}>
                Start a new post, Jack!
              </a>
              {selectedProjectDiv}
            </div>
        )
      }

      return (
          <BasicPage page={page} hideHeader={false}>
          </BasicPage>
      );
    }



    var selectedProjectDiv = null;
    if (this.props.selectedProject != null && FirebaseManager.sharedInstance().dev === true) {
      selectedProjectDiv = (
          <ProjectEditor />
      )
    }

    var showCasedStyle = {
      margin: 'auto',
      width: '100%',
      height: '70vh',
      maxHeight: '150vw',
      objectFit:"cover",
      zIndex:15
    }

    var blogHeader = (
        <div>
          <Image picture={currentBlogProject.picture} highQuality={true} fade fancyLoad={true} style={showCasedStyle}/>
          <div style={{position:'relative', maxWidth:'800px',  width:'90%', margin:'auto'}}>

            <h1 style={{textAlign:'left', position:'relative', fontSize:46}}>
              <div style={{textAlign:'left', position:'relative', fontWeight:400, fontSize:14, marginBottom:'10px'}}>
                {moment(currentBlogProject.getTimestamp()).format("MMM D, YYYY")}
              </div>
              {currentBlogProject.title}
            </h1>


          </div>
        </div>
    )

    if (FirebaseManager.sharedInstance().dev === true) {
      if (this.state.editorState == null) {
        return null;
      }
      page = (
          <div className={"blog"} style={{position:'relative', textAlign:'left', marginTop:'50px', paddingBottom:'200px', maxWidth:'100%', margin:'auto',}}>

            {blogHeader}
            <center>
              <a onClick={()=>{this.editPost()}}>
                Edit Blog Header
              </a>
            </center>
            <br/>
            <br/>

            <div id={"editor"} style={{maxWidth:'800px', width:'90%', margin:'auto', position:'relative'}}>
              <Editor
                  spellCheck
                  plugins={plugins}
                  blockRendererFn={this.myBlockRenderer.bind(this)}
                  defaultEditorState={this.state.editorState}
                  onEditorStateChange={this.onEditorStateChange}
                  toolbarCustomButtons={[
                    <a className={"button"} style={{backgroundColor: this.state.autosavingId ? "gray" : "green"}} onClick={()=>{this.save(currentBlogProject)}}>
                      {this.state.autosavingId ? "Saving..." : "Publish"}
                    </a>
                  ]}
                  toolbarStyle={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000,
                  }}
                  toolbar={{
                    options:['blockType', 'list', 'textAlign', 'link', 'embedded', 'image', 'colorPicker'],
                    image: {
                      urlEnabled: true,
                      uploadEnabled: true,
                      alignmentEnabled: true,
                      previewImage: true,
                      uploadCallback: this.uploadImageCallBack.bind(this),
                      inputAccept: 'image/jpeg,image/jpg,image/png',
                    }
                  }}
              />
            </div>
            <br />
            <br />

            {selectedProjectDiv}
          </div>
      )
    } else {
      page = (
          <div className={"blog"} style={{position:'relative', textAlign:'left', marginTop:'50px', maxWidth:'100%', margin:'auto'}}>
            {blogHeader}
            <div style={{position:'relative', maxWidth:'800px',  width:'90%', margin:'auto'}}>
              <Editor
                  toolbarHidden
                  editorState={this.state.editorState}
                  blockRendererFn={this.myBlockRenderer.bind(this)}
                  readOnly={true}
              />
            </div>
          </div>

      )
    }



    return (
      <BasicPage page={page} hideHeader={FirebaseManager.sharedInstance().dev === true} topMargin={"0px"}>
      </BasicPage>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BlogPostPage);
