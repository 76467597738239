import React, { Component } from "react";
import { connect } from "react-redux";
import { addArticle } from "../actions/index";
import JCIcon from "../../images/JCIcon.png";
import { NavLink } from 'react-router-dom';
const mapStateToProps = state => {
  return {
    pageTitle: state.pageTitle,
  };
};

class PageFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ""
    };
  }

  render() {

    const pageHeaderLinks = [];

    pageHeaderLinks.push(
      {
        title: "HOME",
        link: "/"
      }
    );
    pageHeaderLinks.push(
      {
        title: "CAREER",
        link: "/portfolio/career"
      }
    );
    pageHeaderLinks.push(
      {
        title: "LIFE",
        link: "/portfolio/life"
      }
    );
    pageHeaderLinks.push(
      {
        title: "CREATIONS",
        link: "/portfolio/projects"
      }
    );


    const infoLinks = [];

    infoLinks.push(
      {
        title: "jackjosephconnolly@gmail.com",
        link: "mailto:jackjosephconnolly@gmail.com"
      }
    );

    infoLinks.push(
      {
        title: "+1 (415) 847-2061",
        link: "tel://14158472061",
        align: "center"
      }
    );

    infoLinks.push(
      {
        title: "linkedin.com/in/johnjosephconnolly/",
        link: "https://www.linkedin.com/in/johnjosephconnolly/"
      }
    );

    const linksDiv = pageHeaderLinks.map(link => {
      return (
        <span key={link.title} style={{margin:10,marginTop:0,marginBotton:0, width:'120px', color: 'black', display:'inline-block', }}>
          <NavLink to={link.link}>{link.title}</NavLink>
        </span>
      )
    });

    const infoDiv = infoLinks.map(link => {
      var width = '200px';

      if (link.link === "tel://14158472061") {
        width = "120px"
      }
      return (
        <span key={link.title} style={{margin:20, marginTop:0, marginBotton:0, fontSize:'12px', color: 'black', width:width, display:'inline-block'}}>
          <a href={link.link} target="_blank">{link.title}</a>
        </span>
      )
    });

    // Buy me a coffee link
    // <a href="https://www.buymeacoffee.com/connolly" target="_blank"><img src="https://cdn.buymeacoffee.com/buttons/default-orange.png" alt="Support My Life" style={{height: '30px', width: '130px', borderRadius:'6px'}} /></a>

    return (
      <center className="underlineAnimation" style={{width:'100%', position:'relative', backgroundColor:'white', paddingTop:'40px', paddingBottom:'20px', borderTop:'1px dotted rgba(0,0,0,0.2)'}}>
        <div style={{position:'relative'}}>
          <div className="large-text" style={{minHeight:'60px', fontSize:'22px', position:'relative', lineHeight:'60px', fontWeight:200}}>
            JACK JOSEPH CONNOLLY
          </div>
          <div style={{minHeight:'60px', position:'relative', lineHeight:'60px', width:'50%'}}>
            {linksDiv}
          </div>
          <div style={{minHeight:'50px', position:'relative', lineHeight:'50px'}}>
            {infoDiv}
          </div>

          <div style={{left:"10%", top:'50%', width:'15%', position:'absolute', height:'1px', backgroundColor:'black'}}/>
          <div style={{right:"10%",top:'50%', width:'15%', position:'absolute', height:'1px', backgroundColor:'black'}}/>
        </div>
        <div style={{height:'60px', marginTop:"20px", position:'relative', width:'1px', backgroundColor:'black'}}/>


      </center>

    );
  }
}
export default connect(
  mapStateToProps
)(PageFooter);
