import { ADD_ARTICLE } from "../constants/action-types";

export function addArticle(payload) {
  return { type: ADD_ARTICLE, payload };
}

export function refreshScreen(payload) {
  return { type: "UPDATE", payload };
}

export function allPictures(payload) {
  return { type: "PICTURES", payload };
}

export function allAchievements(payload) {
  return { type: "ACHIEVEMENTS", payload };
}

export function allPortfolios(payload) {
  return { type: "PORTFOLIOS", payload };
}

export function allProjects(payload) {
  return { type: "PROJECTS", payload };
}

export function achievementColors(payload) {
  return { type: "ACHIEVEMENT_COLORS", payload };
}

export function setStateKeyValue(key, value) {
  var payload = {};
  payload.key = key;
  payload.value = value;

  return { type: "SET_KEY_VALUE", payload };
}

export function selectPicture(payload) {
  return { type: "SELECTED_PICTURE", payload };
}

export function selectAchievement(payload) {
  return { type: "SELECTED_ACHIEVEMENT", payload };
}

export function selectProject(payload) {
  return { type: "SELECTED_PROJECT", payload };
}

export function setPictureVisibility(payload) {
  return { type: "PICTURE_VISIBILITY", payload };
}

export function getData() {
  return function(dispatch) {
    return fetch("https://jsonplaceholder.typicode.com/posts")
      .then(response => response.json())
      .then(json => {
        dispatch({ type: "DATA_LOADED", payload: json });
      });
  };
}
