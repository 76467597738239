import React, { Component } from 'react'

import ImageFadeIn from 'react-image-fade-in'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Loader from 'react-loader-spinner'
import {Picture} from "../classes/Picture";

export class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {randomId:Math.random().toString(36).substring(2,22)};
    this.lastLoadedPictureRefId = "";
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var newState = {};

    if (nextProps.picture != null && (prevState.currentPicture == null || nextProps.picture.refID !== prevState.currentPicture.refID)) {
      newState.currentImage = nextProps.picture.getTinyImageURL();
      newState.loading = true;
      newState.smallLoading = true
      newState.currentPicture = nextProps.picture;
      var oldImage = document.getElementById(prevState.randomId);
      var oldSmallImage = document.getElementById(prevState.randomId + "-small");
      if (oldImage != null) {
        oldImage.removeAttribute('src');
      }
      if (oldSmallImage != null) {
        oldSmallImage.removeAttribute('src');
      }
    }

    return newState;
  }


  render () {
    var mediaType = this.props.mediaType;
    var src = this.props.src;
    var lowQualitySrc = "";
    var onClick = this.props.onClick;
    if (onClick == null) {
      onClick = () => {};
    }

    var picture = this.props.picture;


    var style = Object.assign({}, this.props.style);
    var originalStyle = Object.assign({}, this.props.style);
    if (style == null) {
      style = {};
    }
    var dimensions = null;
    if (picture != null && picture instanceof Picture) {
      mediaType = picture.getMediaType();
      if (this.props.highQuality === true) {
        src = picture.getImageURL()
        lowQualitySrc = picture.getTinyImageURL();
      } else if (this.props.lowQuality === true) {
        src = picture.getTinyImageURL()
      } else if (this.props.mediumQuality === true) {
        src = picture.getSmallImageURL()
        lowQualitySrc = picture.getTinyImageURL();
      } else {
        src = picture.getSmallImageURL()
        lowQualitySrc = picture.getTinyImageURL();
      }

      dimensions = picture.getDimensions();

      if (dimensions != null) {
        if (style.width != null && style.height != null) {

        } else if (style.width != null && style.maxWidth != null) {

        } else if (style.height != null && style.maxHeight != null) {

        } else {
          if (style.width != null) {
            style.maxWidth = style.width;
          }
          if (style.height != null) {
            style.maxHeight = style.height;
          }
          style.width = "" + dimensions.width  + "px"
          style.height = "" + dimensions.height + "px"
          if (style.objectFit == null) {
            if (style.maxHeight != null || style.maxWidth != null) {
              if (dimensions.width > dimensions.height) {
                style.height = "auto"
              } else {
                style.width = "auto"
              }
            }
            style.objectFit = "cover"
          }

        }

      }
      style.objectPosition = picture.getObjectPosition()
    }

    // if (this.lastSrc != null && this.lastSrc !== src && this.props.highQuality === true) {
    //   this.lastSrc = src;
    //   setTimeout(function () {
    //       this.forceUpdate();
    //   }.bind(this), 50);
    //   return null;
    // }
    // this.lastSrc = src;

    if (mediaType === "video") {

      var preload = "none"
      if (this.props.playable !== true) {
        style.pointerEvents = "none"
      } else {
        preload = "auto";
      }

      return (
        <video
          {...this.props}
          poster={picture.pictureData.thumbnailURL}
          preload={preload}
          muted playsinline
          controls
          src={src}
          style={style}
          width={this.props.width} height={this.props.height}
        />
      );
    }
    if (this.props.fancyLoad !== true) {
      return (
        <img {...this.props} id={this.state.randomId} style={style} opacityTransition={0.3} src={src} onClick={onClick} />
      )
    } else {

      var lowQualityStyle = {
        transition: 'filter 0.5s, opacity 0.5s',
        filter: "blur(20px)",
        opacity: 1,
      }

      var highQualityStyle = {
        transition: 'filter 0.5s, opacity 0.5s',
        opacity: 1,
        filter: "blur(0px)",
        display:'block'
      }

      var additionalStyle = {
        backgroundColor:'rgba(0,0,0,0.1)'
      }

      var loadingSpinnerStyle = {
        opacity:0.9,
        transition: 'opacity 0.5s',
      }

      if (originalStyle.zIndex != null) {
        loadingSpinnerStyle.zIndex = originalStyle.zIndex + 1;
        highQualityStyle.zIndex = originalStyle.zIndex + 2;
      }

      if (this.state.loading === true && lowQualitySrc !== "" && lowQualitySrc !== src) {
        highQualityStyle.filter = "blur(20px)";
        highQualityStyle.opacity = 0;
        highQualityStyle.position = 'absolute';

      } else {
        lowQualityStyle.position = 'absolute'
        lowQualityStyle.opacity = 0;
        loadingSpinnerStyle.opacity = 0;
      }

      if (this.state.smallLoading === false) {
        additionalStyle.backgroundColor = 'rgba(0,0,0,0.7)'
      }

      return (
        <div style={{ position:'relative', ...additionalStyle, ...originalStyle, overflow:'hidden'}}>

          <img {...this.props} id={this.state.randomId + '-small'} className="fillParent" onLoad={
              () => {
                this.setState({
                    smallLoading:false
                }, () => {

                  // setTimeout(function () {
                  //   this.forceUpdate();
                  // }.bind(this), 1000);


                });

            }} style={{ ...style, ...lowQualityStyle}} src={lowQualitySrc} />
          <div className="absoluteCentered" style={loadingSpinnerStyle}>
            <Loader
                height={40}
                width={40}
                color="#ffffff"
                type="Grid"
            />
          </div>
          <img {...this.props} id={this.state.randomId}  onLoad={
              () => {
                this.setState({
                    loading:false
                }, () => {

                  // setTimeout(function () {
                  //   this.forceUpdate();
                  // }.bind(this), 1000);


                });

            }} style={{...style, ...highQualityStyle}} src={src} />
        </div>

      )
    }
  }
}
