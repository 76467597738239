import React, { Component } from "react";
import { connect } from "react-redux";
import { addArticle } from "../actions/index";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import ShowcasedPhoto from "./ShowcasedPhoto";

function mapDispatchToProps(dispatch) {
  return {
    addArticle: article => dispatch(addArticle(article))
  };
}

class BasicPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ""
    };
  }

  render() {
    var innerPage = null;
    var topMargin = "80px";
    if (this.props.page != null) {
      innerPage = this.props.page;
    }

    if (this.props.topMargin != null) {
      topMargin = this.props.topMargin;
    }

    var pageHeader = (
      <PageHeader />
    )

    var pageFooter = (
      <PageFooter />
    )

    if (this.props.hideHeader === true) {
      pageHeader = null;
    }

    var scrollHandler = null;

    if (this.props.scrollHandler != null) {
      scrollHandler = this.props.scrollHandler;
    }

    return (
      <div id="mainPage" onScroll={scrollHandler} style={{position:'fixed', left:'0px', top:'0px', right:'0px', bottom:'0px', overflowY: 'scroll'}}>
        {pageHeader}
        <div style={{minHeight:'50vh', marginTop:topMargin}}>
          {innerPage}
        </div>

        {pageFooter}
        <ShowcasedPhoto />
        
      </div>
    );
  }
}
export default BasicPage;
