import React, { Component } from "react";
import { connect } from "react-redux";
import { selectPicture } from "../actions/index";
import Dropzone from 'react-dropzone';

import { Image } from "./Image";

const mapStateToProps = state => {
  return {

  };
};

function mapDispatchToProps(dispatch) {
  return {
    selectPicture: picture => dispatch(selectPicture(picture))
  };
}

class TimelineCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ""
    };
  }

  render() {

    var title = "hello";

    if (this.props.title != null) {
      title = this.props.title;
    }

    if (this.props.picture != null && this.props.picture.pictureData != null) {
      var achievementColorIndicator = null;
      if (this.props.picture.taggedAchievements != null) {
        const taggedAchievements = Object.keys(this.props.picture.taggedAchievements);
        const colors = taggedAchievements.map(achievementKey => {
          return (
            <span key={achievementKey} style={{height:'100%', width:"" + 100 / taggedAchievements.length + "%", backgroundColor:this.props.achievementColors[achievementKey]}} />
          )
        })

        achievementColorIndicator = (
          <div style={{height:'10px', left:"2px", right:"0px", bottom:"0px", position:'absolute', display:'flex'}}>
            {colors}
          </div>
        )
      }
      const img = (<Image mediumQuality={true} picture={this.props.picture} className="small-text" style={{width: "100%", height: "100%",objectFit:'cover',objectPosition:'center'}} />)

      var titleDiv = null;

      if (this.props.title != null) {
        titleDiv = (
          <div className="medium-text" style={{position:'absolute', top:'2px', width:'340px', fontWeight:'bold', height:'30px', background:'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 40%, rgba(0,0,0,0.7021183473389356) 100%)', color:'white', padding:'5px'}}>
            {this.props.title}
          </div>
        )
      }

      return (
        <div className="timelineCell" style={{position:'relative', overflow:'visible'}}>
          {img}
          {titleDiv}
          {achievementColorIndicator}
        </div>
      );
    }

    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimelineCell);
