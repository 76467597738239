import React, { Component } from "react";
import * as firebase from 'firebase';
import 'firebase/firestore';
import { DatabaseObject } from "./DatabaseObject";
import { Picture } from "./Picture";
import { Achievement } from "./Achievement";
import store from "../store/index";
import { allProjects, refreshScreen } from "../actions/index";

var moment = require('moment');

export class Project extends DatabaseObject {

  constructor(dict) {
    super();
    this.type = "project";
    this.deserialize(dict);
    this.ignoredFields["pictures"] = true;
    this.ignoredFields["achievements"] = true;
    this.ignoredFields["listeningForPictures"] = true;
    this.ignoredFields["listeningForAchievements"] = true;
    this.ignoredFields["backgroundColorURL"] = true;
    this.ignoredFields["backgroundColor"] = true;


    var goodColors = [
      "35e8ac",
      "fffe86",
      "e3ac20",
      "25a4ec",
      "bb1b43",
      "075b56"
    ]

    this.backgroundColor = goodColors[Math.floor(Math.random() * goodColors.length)] + "1f";
    this.backgroundColorURL = "url(http://api.thumbr.it/whitenoise-361x370.png?background=" + this.backgroundColor + "&noise=626262&density=7&opacity=20)";
  }

  getFirestoreRef() {
    return firebase.firestore().collection(`projects/`);
  }

  getBaseURLForPictures() {
    const baseRefURL = "projects/" + this.refID + "/pictures/";
    return baseRefURL;
  }

  getBaseURLForAchievements() {
    const baseRefURL = "projects/" + this.refID + "/achievements/";
    return baseRefURL;
  }

  getAchievements() {
    if (this.refID == null || this.listeningForAchievements === true) {
      return;
    }
    this.listeningForAchievements = true;
    var self = this;

    const baseRefURL = this.getBaseURLForAchievements();

    firebase.firestore().collection(baseRefURL).orderBy("timestamp", "asc").onSnapshot(function(querySnapshot) {
      const achievements = querySnapshot.docs.map(doc => {
        var achievement = doc.data();
        achievement.refID = doc.id;
        achievement.baseRefURL = baseRefURL;
        achievement.project = self;
        return new Achievement(achievement);
      });
      this.achievements = achievements;
      var update = {};
      update.refreshId = this.visibleId;
      store.dispatch(refreshScreen(update));
    }.bind(this));
  }

  getPictures() {
    if (this.refID == null || this.listeningForPictures === true) {
      return;
    }
    this.listeningForPictures = true;
    var self = this;

    const baseRefURL = this.getBaseURLForPictures();

    firebase.firestore().collection(baseRefURL).orderBy("timestamp", "asc").onSnapshot(function(querySnapshot) {
      const pictures = querySnapshot.docs.map(doc => {
        var picture = doc.data();
        picture.refID = doc.id;
        picture.baseRefURL = baseRefURL;
        picture.project = self;
        return new Picture(picture);
      });
      this.pictures = pictures;
      var update = {};
      update.refreshId = this.visibleId;
      store.dispatch(refreshScreen(update));
    }.bind(this));
  }

  getTimestamp() {
    if (this.timestamp == null) {
      return (new Date()).getTime()
    }
    return this.timestamp
  }

  generateProjectDetails() {
    var projectDetails = {};
    projectDetails.beginTime = (new Date()).getTime();
    projectDetails.endTime = 0;

    if (this.pictures != null) {
      for (var i = 0; i < this.pictures.length; i++) {
        const picture = this.pictures[i];
        var pictureTime = picture.getTimestamp();
        if (pictureTime >= projectDetails.endTime) {
          projectDetails.endTime = pictureTime;
        }
        if (pictureTime <= projectDetails.beginTime) {
          projectDetails.beginTime = pictureTime;
        }
      }
    } else if (this.achievements != null) {
      for (var i = 0; i < this.achievements.length; i++) {
        const achievement = this.achievements[i];
        var achievementTime = achievement.getTimestamp();
        if (achievementTime >= projectDetails.endTime) {
          projectDetails.endTime = achievementTime;
        }
        if (achievementTime <= projectDetails.beginTime) {
          projectDetails.beginTime = achievementTime;
        }
      }
    }

    projectDetails.position = this.position;
    projectDetails.website = this.website;

    return projectDetails
  }

  saveToDatabase(refIdCallback) {
    if (this.visibleId == null && this.refID != null) {
      this.visibleId = this.refID;
    }

    super.saveToDatabase(refIdCallback);
  }

  deserialize(dict) {
    for (var key in dict) {
      if (dict.hasOwnProperty(key)) {
        if (key == "picture") {
          const picture = new Picture(dict[key]);
          this[key] = picture;
        } else {
          this[key] = dict[key];
        }
      }
    }
  }
}
