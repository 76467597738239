import React, { Component } from "react";
import { connect } from "react-redux";
import { selectAchievement } from "../actions/index";
import Dropzone from 'react-dropzone';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import DatePicker from 'react-datepicker';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { HuePicker } from 'react-color';
import { Picture } from "../classes/Picture";

import "react-datepicker/dist/react-datepicker.css";

const FirebaseManager = require("../classes/FirebaseManager.js");

const mapStateToProps = state => {
  return {
    selectedAchievement: state.selectedAchievement,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    selectAchievement: achievement => dispatch(selectAchievement(achievement))
  };
}

const tags = [
  "career",
  "project",
  "travel",
  "family",
  "love"
]


class AchievementEditor extends Component {
  constructor(props) {
    super(props);
    var title = "";
    var description = "";
    var color = "white"

    if (props.selectedAchievement.title != null) {
      title = props.selectedAchievement.title;
    }
    if (props.selectedAchievement.description != null) {
      description = props.selectedAchievement.description;
    }
    if (props.selectedAchievement.color != null) {
      color = props.selectedAchievement.color;
    }
    this.state = {
      title: title,
      description: description,
      color : color,
      selectedTags: [],
      changed: false,
      timestamp: 0,
    };
  }

  handleSelectChange(event) {
    this.setState({
      selectedTags: event.target.value
    })
  }

  saveAchievement() {
    this.props.selectedAchievement.title = this.state.title;
    this.props.selectedAchievement.description = this.state.description;
    this.props.selectedAchievement.color = this.state.color;
    if (this.state.timestamp > 0) {
      this.props.selectedAchievement.timestamp = this.state.timestamp;
    }

    FirebaseManager.sharedInstance().saveIfPossible(this.props.selectedAchievement);
  }

  deleteAchievement() {
    FirebaseManager.sharedInstance().deleteIfPossible(this.props.selectedAchievement);
    this.props.selectAchievement(null)
  }

  handleChangeComplete = (color) => {
    this.setState({ color: color.hex });
  };

  onDrop(acceptedFiles, rejectedFiles) {
    var firstPicture = null;

    this.saveAchievement()

    acceptedFiles.forEach(file => {
      const randomID = Math.random().toString(36).substring(2,22);
      const url = URL.createObjectURL(file);

      var newPicture;
      if (firstPicture == null) {
        firstPicture = randomID;

        if (this.props.selectedAchievement.picture != null) {
          newPicture = this.props.selectedAchievement.picture;
          newPicture.deletePictureDataFromStorage();
        } else {
          newPicture = new Picture();
        }


        newPicture.project = this.props.selectedAchievement.project
        if (newPicture.pictureData != null) {
          newPicture.pictureData.imageURL = url;
        } else {
          newPicture.pictureData = {imageURL:url};
        }

        if (newPicture.refID == null) {
          newPicture.saveToDatabase();
        }
        console.log("what ")
        this.props.selectedAchievement.picture = newPicture;

        this.props.selectAchievement(this.props.selectedAchievement);
        this.setState({changed:!this.state.changed});
      } else {
        const newPictureData = {pictureData:{imageURL:url}};
        newPicture = new Picture(newPictureData);
        newPicture.project = this.props.selectedAchievement.project

        newPicture.saveToDatabase();
      }


      const self = this;
      function callback(picture) {
        if (picture instanceof Picture) {
          if (self.props.selectedAchievement.refID != null) {
            if (newPicture.taggedAchievements == null) {
              newPicture.taggedAchievements = {};
            }
            newPicture.taggedAchievements[self.props.selectedAchievement.refID] = true;
          }
          if (firstPicture === randomID) {
            newPicture.pictureData = picture.pictureData;
            if (newPicture.pictureData.timestamp != null) {
              newPicture.timestamp = newPicture.pictureData.timestamp
            }


            newPicture.saveToDatabase();

            self.props.selectedAchievement.picture = newPicture;

            self.props.selectAchievement(self.props.selectedAchievement);
            self.setState({changed:!self.state.changed, timestamp:newPicture.getTimestamp()})

          } else {
            newPicture.pictureData = picture.pictureData;
            if (newPicture.pictureData.timestamp != null) {
              newPicture.timestamp = newPicture.pictureData.timestamp
            }
            newPicture.saveToDatabase();
          }
        }
      }
      FirebaseManager.sharedInstance().uploadImage(file, callback);
    });
  }

  typeHandler(e) {
    switch (e.target.id) {
      case "title":
        this.setState({title:e.target.value})
        break;
      case "description":
        this.setState({description:e.target.value})
        break;
      default:
        return;
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selectedAchievement != null && prevState.timestamp === 0) {

      var title = "";
      var description = "";
      var taggedAchievements = [];
      var timestamp = 0;

      if (nextProps.selectedAchievement.getTimestamp() !== 0) {
        timestamp = nextProps.selectedAchievement.getTimestamp();
      }

      var newState =  {
        timestamp: timestamp
      }


      return newState;
    }

    return null;
  }

  render() {

    var title = "hello";

    if (this.props.title != null) {
      title = this.props.title;
    }

    var dropzoneInnerDiv = (
      <div className="small-text" style={{height:'400px',lineHeight:'400px', backgroundColor:'rgb(200, 200, 200)'}}>
        Drag photo or click here to upload.
      </div>
    );

    if (this.props.selectedAchievement.picture != null) {
      dropzoneInnerDiv = (
        <img src={this.props.selectedAchievement.picture.getImageURL()} className="small-text" width="300px" style={{width: "100%",height: "400px",objectFit:'cover',objectPosition:'center'}} />
      );
    }

    console.log(this.props.selectedAchievement.project);

    var startDate = new Date(this.state.timestamp);

    if (this.state.timestamp === 0) {
      startDate = new Date();
    }

    var colorAndTags = null;

    if (false) {
      colorAndTags = (
        <div>
          <div className="small-text" style={{textAlign:'left'}}>
            Section Color:
            <br />
            <br />
            <HuePicker
              color={ this.state.color }
              onChangeComplete={ this.handleChangeComplete.bind(this) }  />
            <br />
          </div>


          <FormControl style={{width:'100%'}}>
            <InputLabel id="demo-mutiple-name-label">Tags</InputLabel>
            <Select
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              multiple
              value={this.state.selectedTags}
              onChange={this.handleSelectChange.bind(this)}
              style={{width:'100%'}}
              input={<Input />}
            >
              {tags.map(tag => (
                <MenuItem key={tag} value={tag}>
                  {tag}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )
    }

    return (
      <div className="fullscreen darkOverlay" style={{textAlign:'center', display:'flex',}}>
        <div className="fullscreen" onClick={()=>{this.props.selectAchievement(null)}} />
        <div style={{margin:'auto', position:'relative', maxHeight:'80%', maxWidth:'90%', overflow:'scroll', width:'600px', backgroundColor:"rgb(255, 255, 255)"}} >
            <Dropzone onDrop={this.onDrop.bind(this)} accept="image/jpeg, image/png" style={{width:'100%', height:'400px'}}>
              {({getRootProps, getInputProps}) => (
                <section style={{width: "100%",height: "400px",verticalAlign:'middle'}}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {dropzoneInnerDiv}
                  </div>
                </section>
              )}
            </ Dropzone>
            <form noValidate autoComplete="off" style={{padding:'8px'}}>
              <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <TextField
                    onChange={this.typeHandler.bind(this)}
                    id="title"
                    label="Title"
                    defaultValue={this.props.selectedAchievement.title}
                    margin="normal"
                    variant="outlined"
                    style={{width: '60%'}}
                  />
                <div style={{marginRight: '40px'}}>
                    <DatePicker
                      selected={startDate}
                      onChange={date => this.setState({timestamp:date.getTime()})}
                      timeFormat="HH:mm"
                      popperPlacement="bottom-begin"
                      showYearDropdown
                      dateFormat="MMM d, yyyy h:mm aa"

                    />
                </div>
              </div>
              <div>
                <TextField
                  onChange={this.typeHandler.bind(this)}
                  id="description"
                  label="Description"
                  defaultValue={this.props.selectedAchievement.description}
                  multiline
                  margin="normal"
                  variant="outlined"
                  style={{width: '100%'}}
                />
              </div>

              <br />

              <br />
              <br />
              {colorAndTags}
              <br />
              <br />
              <span>
                <Button variant="contained" color="primary" style={{backgroundColor:'rgb(161, 5, 5)', width:'40%', margin:'5px'}} onClick={this.deleteAchievement.bind(this)}>
                  Delete
                </Button>
              </span>
              <span>
                <Button variant="contained" color="primary" style={{backgroundColor:'rgb(26, 149, 68)', width:'40%', margin:'5px'}} onClick={this.saveAchievement.bind(this)}>
                  Save
                </Button>
              </span>
            </form>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AchievementEditor);
