export class DatabaseObject {

  constructor(dict) {
    this.deserialize(dict);
    this.ignoredFields = {
      ignoredFields: true,
    }
  }

  getFirestoreRef() {
    return null;
  }

  saveToDatabase(refIdCallback) {
    const firestoreRef = this.getFirestoreRef();
    if (firestoreRef == null) {
      return;
    }
    if (this.refID == null) {
      const self = this;
      firestoreRef.add(this.serialize()).then(function(docRef) {
        self.refID = docRef.id;
        if (refIdCallback) {
          refIdCallback(docRef.id)
        }
      });
    } else {
      firestoreRef.doc(this.refID).set(this.serialize());
      if (refIdCallback != null) {
        refIdCallback(this.refID)
      }
    }

  }

  deleteFromDatabase() {
    const firestoreRef = this.getFirestoreRef();
    if (firestoreRef != null && this.refID != null) {
      firestoreRef.doc(this.refID).delete();
    }

    for (var key in this) {
      if (this.hasOwnProperty(key)) {
        const object = this[key];
        if (object instanceof DatabaseObject) {
          // object.deleteFromDatabase();
        }
      }
    }
  }

  serialize() {
    var json = {};
    for (var key in this) {

      if (this.hasOwnProperty(key) && this.ignoredFields[key] == null) {
        const object = this[key];
        if (object instanceof DatabaseObject) {
          json[key] = object.serialize();
        } else {
          json[key] = this[key];
        }
      }
    }
    return json;
  }

  deserialize(dict) {
    for (var key in dict) {
      if (dict.hasOwnProperty(key)) {
        this[key] = dict[key];
      }
    }
  }
}
